// @flow

import { jsonGetRequest, jsonPostRequest } from '../requests';
import { setMessageAction } from '../messages/actions';
import type { Suggestions } from '../../entities/types';
import Institution from '../../entities/models/Institution';
import type {
    InstitutionReferralsCleanUp,
    InstitutionReferralsReceiveInstitution,
    InstitutionReferralsReceiveLink,
    InstitutionReferralsReceiveList,
} from './types';
import InstitutionReferral from '../../entities/models/InstitutionReferral';

function receiveInstitution(response: {
    entity: Object,
    suggestions: Suggestions,
}): InstitutionReferralsReceiveInstitution {
    return {
        type: 'institutionReferrals/receiveInstitution',
        institution: new Institution(response.entity),
        institutionSuggestions: response.suggestions,
    };
}

export function fetchInstitution(id: string) {
    return (dispatch) => {
        jsonGetRequest(Institution.endpoints.info(id))
            .then((value) => dispatch(receiveInstitution(value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

function receiveReferralList(response: {
    data: { top_offer_id: string, offers: [{ offer_id: string, referrals: Array<Object> }] },
    suggestions: {},
}): InstitutionReferralsReceiveList {
    // Map referrals to records
    response.data.offers.forEach((o) => {
        o.referrals = o.referrals.map((r) => new InstitutionReferral(r));
    });
    return {
        type: 'institutionReferrals/receiveList',
        referralData: response.data,
        referralSuggestions: response.suggestions,
    };
}

export function fetchReferralList(institution: Institution) {
    return (dispatch) => {
        const listPromise = jsonGetRequest(InstitutionReferral.endpoints.listByInstitution(institution.id));

        listPromise
            .then((value) => dispatch(receiveReferralList(value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function cleanUpInstitutionReferrals(): InstitutionReferralsCleanUp {
    return {
        type: 'institutionReferrals/cleanUp',
    };
}

function receiveLink(referralId: string, response: { link: string }): InstitutionReferralsReceiveLink {
    return {
        type: 'institutionReferrals/receiveLink',
        link: response.link,
        referralId,
    };
}

export function sendLinkForm(referralId: string, body: Object) {
    return (dispatch) => {
        const formPromise = jsonPostRequest(InstitutionReferral.endpoints.link(referralId), JSON.stringify(body));
        formPromise
            .then((value) => dispatch(receiveLink(referralId, value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}
