// @flow

import React, { Component } from 'react';
import { Form, Button, Container, Table, Alert, FormGroup, Label } from 'reactstrap';
import { withRouter } from 'react-router';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';

import StyleClasses from '../../styles/StyleClasses';
import LoadingContainer from '../basicElements/LoadingContainer';
import ControlledInputField from '../basicElements/controls/ControlledInputField';
import TypeaheadInputField from '../basicElements/controls/TypeaheadInputField';

import type { StoredEntity } from '../../entities/abstract/StoredEntity';
import type { EntityFieldCollection, Suggestions } from '../../entities/types';
import { getDefaultValueForField } from '../helpers';
import BooleanControlledInputField from '../basicElements/controls/BooleanControlledInputField';
import type { InternalFormData } from '../types';
import FileInputField from '../basicElements/controls/FileInputField';
import User from '../../entities/models/User';
import CreditCard from '../../entities/models/CreditCard';
import {
    addCreditCardReferral,
    addInstitutionReferral,
    cleanUpUserProfile,
    deleteCreditCardReferral,
    deleteInstitutionReferral,
    editUserProfile,
    fetchUser,
    fetchUserCreditCardReferrals,
    fetchUserInstitutionReferrals,
    updateCreditCardReferral,
    updateInstitutionReferral,
} from '../../store/userProfile/actions';
import CreditCardReferral from '../../entities/models/CreditCardReferral';
import CreditCardOffer from '../../entities/models/CreditCardOffer';
import type { UserProfileCardReferralData, UserProfileInstitutionReferralData } from '../../store/userProfile/types';
import '../../styles/profile.css';
import InstitutionOffer from '../../entities/models/InstitutionOffer';
import Institution from '../../entities/models/Institution';
import InstitutionReferral from '../../entities/models/InstitutionReferral';

//
// PROPS
//

type PropsType = {
    // INJECTED FUNCTIONS
    fetchUser: () => void,
    fetchUserCreditCardReferrals: () => void,
    fetchUserInstitutionReferrals: () => void,
    editUserProfile: (entity: StoredEntity, data: InternalFormData) => void,
    addCreditCardReferral: (ccId: string, link: string) => void,
    updateCreditCardReferral: (refId: string, link: string) => void,
    deleteCreditCardReferral: (refId: string) => void,
    addInstitutionReferral: (institutionId: string, link: string) => void,
    updateInstitutionReferral: (refId: string, link: string) => void,
    deleteInstitutionReferral: (refId: string) => void,
    cleanUpUserProfile: () => void,
    // STATE
    user: User,
    cardReferrals: UserProfileCardReferralData,
    institutionReferrals: UserProfileInstitutionReferralData,
    suggestions: Suggestions,
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {
    userProfile: InternalFormData,
    currentCreditCardReferral: {
        cardId: string,
        link: string,
    },
};

//
// CLASS
//

class UserProfileView extends Component<PropsType, StateType> {
    fields: EntityFieldCollection;

    constructor(props) {
        super(props);
        this.fields = {};
        this.fileFields = {};
        this.state = {
            userProfile: {},
            currentCreditCardReferral: {
                cardId: undefined,
                offerId: undefined,
                link: undefined,
            },
            currentInstitutionReferral: {
                institutionId: undefined,
                offerId: undefined,
                link: undefined,
            },
        };

        Object.entries(User.f).forEach(([name, field]) => {
            if (field.canEdit) {
                this.fields[name] = field;
                this.state.userProfile[name] = getDefaultValueForField(field);
            }
        });

        Object.entries(User.fileFields).forEach(([name, field]) => {
            this.fileFields[name] = field;
            this.state.userProfile[name] = null;
        });
    }

    changeUserProfileFormState(id, value) {
        this.setState({
            userProfile: {
                ...this.state.userProfile,
                [id]: value,
            },
        });
    }

    submitUserProfile(event) {
        event.preventDefault();
        this.props.editUserProfile(this.props.user, this.state.userProfile);
    }

    componentDidMount() {
        this.props.fetchUser();
    }

    componentDidUpdate(prevProps: PropsType) {
        // Suggestions
        const e = this.props.user;
        const s = this.props.suggestions;
        if (e && s && (!prevProps.user || !prevProps.suggestions)) {
            // Fetch user referrals after fetching profile
            this.props.fetchUserCreditCardReferrals(e);
            this.props.fetchUserInstitutionReferrals(e);

            // Set the fields to their initial values
            const toSet = {
                userProfile: {},
            };

            Object.entries(User.f).forEach(([name, field]) => {
                if (field.canEdit) {
                    switch (field.dataType.primitive) {
                        case 'ref': {
                            const sName = field.dataType.extraData.model.classInternalName;
                            if (field.dataType.isArray) {
                                toSet.userProfile[name] = s[sName].filter((se) => e.r[field.key].includes(se.id));
                            } else {
                                toSet.userProfile[name] = s[sName].filter((se) => se.id === e.r[field.key]);
                            }
                            break;
                        }
                        case 'simple-entity':
                            toSet.userProfile[name] = JSON.stringify(e.r[field.key]);
                            break;
                        default:
                            toSet.userProfile[name] = e.r[field.key];
                    }
                }
            });

            this.setState(toSet);
        }

        // Set the current card ID
        if (prevProps.cardReferrals === undefined && this.props.cardReferrals) {
            if (this.props.cardReferrals.length === 0) return;

            const cardRecord = this.props.cardReferrals[0];
            const cardId = cardRecord.card_id;
            const offerRecord = cardRecord.offers[0];
            const offerId = offerRecord.offer_id;
            const referralLink = offerRecord.referral ? offerRecord.referral.r[CreditCardReferral.f.link.key] : '';

            this.setState({
                currentCreditCardReferral: {
                    cardId: cardId,
                    offerId: offerId,
                    link: referralLink,
                },
            });
        }

        // Set the institution ID
        if (prevProps.institutionReferrals === undefined && this.props.institutionReferrals) {
            if (this.props.institutionReferrals.length === 0) return;

            const institutionRecord = this.props.institutionReferrals[0];
            const institutionId = institutionRecord.institution_id;
            const offerRecord = institutionRecord.offers[0];
            const offerId = offerRecord.offer_id;
            const referralLink = offerRecord.referral ? offerRecord.referral.r[InstitutionReferral.f.link.key] : '';

            this.setState({
                currentInstitutionReferral: {
                    institutionId: institutionId,
                    offerId: offerId,
                    link: referralLink,
                },
            });
        }
    }

    componentWillUnmount(): void {
        this.props.cleanUpUserProfile();
    }

    getControlsForFields(): [Component] {
        const fields = [];

        Object.entries(this.fields).forEach(([name, field]) => {
            let control;

            // TODO: Handle plural values
            switch (field.dataType.primitive) {
                case 'str':
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="text"
                            value={this.state.userProfile[name]}
                            onChange={(id, value) => this.changeUserProfileFormState(id, value)}
                        />
                    );
                    break;
                case 'simple-entity':
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="textarea"
                            value={this.state.userProfile[name]}
                            onChange={(id, value) => this.changeUserProfileFormState(id, value)}
                        />
                    );
                    break;
                case 'num':
                case 'enum': // TODO: Make it a segmented control
                case 'ts': // TODO: Make it a date picker
                    control = (
                        <ControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            inputType="number"
                            value={this.state.userProfile[name]}
                            onChange={(id, value) => this.changeUserProfileFormState(id, value)}
                        />
                    );
                    break;
                case 'ref':
                    control = (
                        <TypeaheadInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            maxValues={field.dataType.extraData.maxValues}
                            initialValue={this.state.userProfile[name]}
                            options={this.props.suggestions[field.dataType.extraData.model.classInternalName]}
                            onChange={(id, values) => this.changeUserProfileFormState(id, values)}
                        />
                    );
                    break;
                case 'bool':
                    control = (
                        <BooleanControlledInputField
                            id={`${name}`}
                            key={`${name}`}
                            displayName={`${field.displayName}`}
                            value={this.state.userProfile[name]}
                            onChange={(id, value) => this.changeUserProfileFormState(id, value)}
                        />
                    );
                    break;
                default:
                    break;
            }

            fields.push(control);
        });

        return fields;
    }

    getControlsForFileFields(): [Component] {
        const fileFields = [];
        Object.entries(this.fileFields).forEach(([name, field]) => {
            const control = (
                <FileInputField
                    id={name}
                    key={name}
                    displayName={field.displayName}
                    onFileUpdated={(id, value) => this.changeUserProfileFormState(id, value)}
                />
            );
            fileFields.push(control);
        });
        return fileFields;
    }

    updateCardRefLink(value) {
        this.setState({
            currentCreditCardReferral: {
                ...this.state.currentCreditCardReferral,
                link: value,
            },
        });
    }

    updateRefCardId(cardId, offerId) {
        if (cardId === null) {
            this.setState({
                currentCreditCardReferral: {
                    cardId: null,
                    offerId: null,
                    link: '',
                },
            });
            return;
        }

        const cardRecord = this.props.cardReferrals.find((cr) => cr.card_id === cardId);
        const offerRecord = cardRecord.offers[0];
        this.setState(
            {
                currentCreditCardReferral: {
                    cardId: cardId,
                    offerId: offerRecord.offer_id,
                    link: offerRecord.referral ? offerRecord.referral.r[CreditCardReferral.f.link.key] : '',
                },
            },
            () => {
                if (offerId) {
                    this.updateCardRefOfferId(offerId);
                }
            },
        );
    }

    updateCardRefOfferId(value) {
        const cardRecord = this.props.cardReferrals.find(
            (cr) => cr.card_id === this.state.currentCreditCardReferral.cardId,
        );
        const offerRecord = cardRecord.offers.find((or) => or.offer_id === value);
        this.setState({
            currentCreditCardReferral: {
                ...this.state.currentCreditCardReferral,
                offerId: value,
                link: offerRecord.referral ? offerRecord.referral.r[CreditCardReferral.f.link.key] : '',
            },
        });
    }

    getCardChooser() {
        const cardId = this.state.currentCreditCardReferral.cardId;
        const selected = cardId
            ? [
                  this.props.suggestions[CreditCard.classInternalName].find(
                      (cr) => cr.id === this.state.currentCreditCardReferral.cardId,
                  ),
              ]
            : [];
        return (
            <TypeaheadInputField
                displayName="Credit Card (start typing the card name, only one card at a time)"
                id="card-chooser-typeahead"
                displayFormGroup={true}
                initialValue={selected}
                options={this.props.suggestions[CreditCard.classInternalName]}
                maxValues={1}
                onChange={(id, entities) => {
                    if (entities.length < 1) this.updateRefCardId(null);
                    else this.updateRefCardId(entities[0].id);
                }}
            />
        );
    }

    getCardOfferChooser() {
        const cardId = this.state.currentCreditCardReferral.cardId;
        if (!cardId) {
            return;
        }

        const cardRecord = this.props.cardReferrals.find((cr) => cr.card_id === cardId);
        const options = [];
        cardRecord.offers.forEach((or) => {
            or.name = this.props.suggestions[CreditCardOffer.classInternalName].find(
                (sr) => sr.id === or.offer_id,
            ).display_name;
        });

        cardRecord.offers.sort((a, b) => {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            else return 0;
        });

        cardRecord.offers.forEach((or) => {
            options.push(
                <option value={or.offer_id} key={or.offer_id}>
                    {or.name}
                </option>,
            );
        });

        return (
            <FormGroup id="card-offer-chooser">
                <Label for="card-offer-chooser">Offer</Label>
                <br />
                <select onChange={(event) => this.updateCardRefOfferId(event.target.value)}>{options}</select>
            </FormGroup>
        );
    }

    submitCreditCardReferral(event, cb) {
        event.preventDefault();
        cb();
    }

    getReferralControlForCurrentCardOffer() {
        // Try to find the referral
        const cardId = this.state.currentCreditCardReferral.cardId;
        if (!cardId) {
            return;
        }

        const offerId = this.state.currentCreditCardReferral.offerId;
        const offerRecord = this.props.cardReferrals
            .find((cr) => cr.card_id === cardId)
            .offers.find((or) => or.offer_id === offerId);
        let refId;
        if (offerRecord.referral) {
            refId = offerRecord.referral.id;
        }
        // User already has referral, display change and delete controls
        if (refId) {
            return (
                <Form>
                    <ControlledInputField
                        id="link"
                        displayName="Change Your Link / Referral Code"
                        inputType="text"
                        value={this.state.currentCreditCardReferral.link}
                        onChange={(id, value) => this.updateCardRefLink(value)}
                    />
                    <Button
                        onClick={(event) => {
                            this.submitCreditCardReferral(event, () =>
                                this.props.updateCreditCardReferral(
                                    refId,
                                    offerRecord.referral.r[CreditCardReferral.f.link.key],
                                    this.state.currentCreditCardReferral.link,
                                ),
                            );
                        }}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={(event) => {
                            this.submitCreditCardReferral(event, () => this.props.deleteCreditCardReferral(refId));
                            this.updateCardRefLink('');
                        }}
                        style={{ marginLeft: '20px' }}
                    >
                        Delete Your Referral
                    </Button>
                </Form>
            );
        } else {
            return (
                <Form>
                    <ControlledInputField
                        id="link"
                        displayName="Add Your Link / Referral Code"
                        inputType="url"
                        value={this.state.currentCreditCardReferral.link}
                        onChange={(id, value) => this.updateCardRefLink(value)}
                    />
                    <Button
                        onClick={(event) => {
                            this.submitCreditCardReferral(event, () =>
                                this.props.addCreditCardReferral(offerId, this.state.currentCreditCardReferral.link),
                            );
                        }}
                    >
                        Submit
                    </Button>
                </Form>
            );
        }
    }

    render() {
        if (
            this.props.user === undefined ||
            this.props.cardReferrals === undefined ||
            this.props.suggestions === undefined
        ) {
            return <LoadingContainer />;
        }

        const warning = this.props.user.r[User.f.isRanked.key] ? null : (
            <Alert color="danger">
                <h5>Warning</h5>
                <hr />
                You can add and edit your referral links, but we will not show them because your subreddit karma is less
                than {this.props.user.r[User.f.redditMinRefScore.key]} in the last{' '}
                {this.props.user.r[User.f.redditScoreDays.key]} days, or your Reddit account has been created less than{' '}
                {this.props.user.r[User.f.redditMinAgeDays.key]} days ago. You may need to wait for up to 3 days from
                the moment your karma goes beyond 10 for it to reflect in our system.
            </Alert>
        );

        let cardRefComponent;
        if (this.props.cardReferrals.length > 0) {
            const cardRefTableRows = [];
            this.props.cardReferrals.forEach((cr) => {
                cr.offers.forEach((or) => {
                    const ref = or.referral;
                    if (ref) {
                        const card = this.props.suggestions[CreditCard.classInternalName].find(
                            (sr) => sr.id === cr.card_id,
                        );
                        const offer = this.props.suggestions[CreditCardOffer.classInternalName].find(
                            (sr) => sr.id === or.offer_id,
                        );

                        const updateButton = (
                            <Button
                                onClick={() => {
                                    this.updateRefCardId(card.id, offer.id);
                                }}
                            >
                                Update
                            </Button>
                        );
                        const deleteButton = (
                            <Button
                                onClick={(event) => {
                                    this.submitCreditCardReferral(event, () =>
                                        this.props.deleteCreditCardReferral(ref.id),
                                    );
                                    if (
                                        this.state.currentCreditCardReferral.offerId ===
                                        ref.r[CreditCardReferral.f.offerId.key]
                                    ) {
                                        this.updateCardRefLink('');
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        );

                        cardRefTableRows.push(
                            <tr key={ref.id}>
                                <td>{card.display_name}</td>
                                <td>{offer.display_name}</td>
                                <td>{ref.r[CreditCardReferral.f.clicksOneDay.key]}</td>
                                <td>{ref.r[CreditCardReferral.f.clicksThirtyDays.key]}</td>
                                <td>
                                    {new Date(ref.r[CreditCardReferral.f.expiration.key] * 1000).toLocaleDateString(
                                        'en-US',
                                    )}
                                </td>
                                <td className="ref-actions">
                                    {updateButton}
                                    {deleteButton}
                                </td>
                            </tr>,
                        );
                    }
                });
            });

            const cardRefTable = cardRefTableRows.length ? (
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Card</th>
                            <th>Offer</th>
                            <th>Clicks in 24H</th>
                            <th>Clicks in 30D</th>
                            <th>Expiration</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{cardRefTableRows}</tbody>
                </Table>
            ) : null;

            cardRefComponent = (
                <div>
                    <h4 className="mb-4 mt-4">My Card Referrals</h4>
                    <div className="mb-4 mt-3">{warning}</div>
                    <div className="mb-4">
                        <div className="mb-3 mt-3">{this.getCardChooser()}</div>
                        <div className="mb-3 mt-3">{this.getCardOfferChooser()}</div>
                        {this.getReferralControlForCurrentCardOffer()}
                    </div>
                    {cardRefTable}
                </div>
            );
        }

        let institutionComponent;
        if (this.props.institutionReferrals.length > 0) {
            const institutionRefTableRows = [];
            this.props.institutionReferrals.forEach((cr) => {
                cr.offers.forEach((or) => {
                    const ref = or.referral;
                    if (ref) {
                        const institution = this.props.suggestions[Institution.classInternalName].find(
                            (sr) => sr.id === cr.institution_id,
                        );
                        const offer = this.props.suggestions[InstitutionOffer.classInternalName].find(
                            (sr) => sr.id === or.offer_id,
                        );

                        const updateButton = (
                            <Button
                                onClick={() => {
                                    this.updateRefInstitutionId(institution.id, offer.id);
                                }}
                            >
                                Update
                            </Button>
                        );
                        const deleteButton = (
                            <Button
                                onClick={(event) => {
                                    this.submitInstitutionReferral(event, () =>
                                        this.props.deleteInstitutionReferral(ref.id),
                                    );
                                    if (
                                        this.state.currentInstitutionReferral.offerId ===
                                        ref.r[InstitutionReferral.f.offerId.key]
                                    ) {
                                        this.updateInstitutionRefLink('');
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        );

                        institutionRefTableRows.push(
                            <tr key={ref.id}>
                                <td>{institution.display_name}</td>
                                <td>{offer.display_name}</td>
                                <td>{ref.r[InstitutionReferral.f.clicksOneDay.key]}</td>
                                <td>{ref.r[InstitutionReferral.f.clicksThirtyDays.key]}</td>
                                <td>
                                    {new Date(ref.r[InstitutionReferral.f.expiration.key] * 1000).toLocaleDateString(
                                        'en-US',
                                    )}
                                </td>
                                <td className="ref-actions">
                                    {updateButton}
                                    {deleteButton}
                                </td>
                            </tr>,
                        );
                    }
                });
            });

            const institutionRefTable = institutionRefTableRows.length ? (
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Institution</th>
                            <th>Offer</th>
                            <th>Clicks in 24H</th>
                            <th>Clicks in 30D</th>
                            <th>Expiration</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{institutionRefTableRows}</tbody>
                </Table>
            ) : null;

            institutionComponent = (
                <div>
                    <h4 className="mb-4 mt-4">My Other Referrals</h4>
                    <div className="mb-4 mt-3">{warning}</div>
                    <div className="mb-4">
                        <div className="mb-3 mt-3">{this.getInstitutionChooser()}</div>
                        <div className="mb-3 mt-3">{this.getInstitutionOfferChooser()}</div>
                        {this.getReferralControlForCurrentInstitutionOffer()}
                    </div>
                    {institutionRefTable}
                </div>
            );
        }

        return (
            <div>
                <h2 className={StyleClasses.spacedElement}>My Profile</h2>
                <Container fluid>
                    <h4 className="mb-4 mt-4">My Information</h4>
                    <Form onSubmit={(event) => this.submitUserProfile(event)}>
                        {this.getControlsForFields()}
                        {this.getControlsForFileFields()}
                        <Button className={StyleClasses.bottomSpacedElement}>Submit</Button>
                    </Form>
                    {cardRefComponent}
                    {institutionComponent}
                </Container>
            </div>
        );
    }

    updateInstitutionRefLink(value) {
        this.setState({
            currentInstitutionReferral: {
                ...this.state.currentInstitutionReferral,
                link: value,
            },
        });
    }

    updateRefInstitutionId(institutionId, offerId) {
        if (institutionId === null) {
            this.setState({
                currentInstitutionReferral: {
                    institutionId: null,
                    offerId: null,
                    link: '',
                },
            });
            return;
        }

        const institutionRecord = this.props.institutionReferrals.find((cr) => cr.institution_id === institutionId);
        const offerRecord = institutionRecord.offers[0];
        this.setState(
            {
                currentInstitutionReferral: {
                    institutionId: institutionId,
                    offerId: offerRecord.offer_id,
                    link: offerRecord.referral ? offerRecord.referral.r[InstitutionReferral.f.link.key] : '',
                },
            },
            () => {
                if (offerId) {
                    this.updateInstitutionRefOfferId(offerId);
                }
            },
        );
    }

    updateInstitutionRefOfferId(value) {
        const institutionRecord = this.props.institutionReferrals.find(
            (cr) => cr.institution_id === this.state.currentInstitutionReferral.institutionId,
        );
        const offerRecord = institutionRecord.offers.find((or) => or.offer_id === value);
        this.setState({
            currentInstitutionReferral: {
                ...this.state.currentInstitutionReferral,
                offerId: value,
                link: offerRecord.referral ? offerRecord.referral.r[InstitutionReferral.f.link.key] : '',
            },
        });
    }

    getInstitutionChooser() {
        const institutionId = this.state.currentInstitutionReferral.institutionId;
        const selected = institutionId
            ? [
                  this.props.suggestions[Institution.classInternalName].find(
                      (cr) => cr.id === this.state.currentInstitutionReferral.institutionId,
                  ),
              ]
            : [];
        return (
            <TypeaheadInputField
                displayName="Institution (start typing the institution name, only one institution at a time)"
                id="institution-chooser-typeahead"
                displayFormGroup={true}
                initialValue={selected}
                options={this.props.suggestions[Institution.classInternalName]}
                maxValues={1}
                onChange={(id, entities) => {
                    if (entities.length < 1) this.updateRefInstitutionId(null);
                    else this.updateRefInstitutionId(entities[0].id);
                }}
            />
        );
    }

    getInstitutionOfferChooser() {
        const institutionId = this.state.currentInstitutionReferral.institutionId;
        if (!institutionId) {
            return;
        }

        const institutionRecord = this.props.institutionReferrals.find((cr) => cr.institution_id === institutionId);
        const options = [];
        institutionRecord.offers.forEach((or) => {
            or.name = this.props.suggestions[InstitutionOffer.classInternalName].find(
                (sr) => sr.id === or.offer_id,
            ).display_name;
        });

        institutionRecord.offers.sort((a, b) => {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            else return 0;
        });

        institutionRecord.offers.forEach((or) => {
            options.push(
                <option value={or.offer_id} key={or.offer_id}>
                    {or.name}
                </option>,
            );
        });

        return (
            <FormGroup id="institution-offer-chooser">
                <Label for="institution-offer-chooser">Offer</Label>
                <br />
                <select onChange={(event) => this.updateInstitutionRefOfferId(event.target.value)}>{options}</select>
            </FormGroup>
        );
    }

    submitInstitutionReferral(event, cb) {
        event.preventDefault();
        cb();
    }

    getReferralControlForCurrentInstitutionOffer() {
        // Try to find the referral
        const institutionId = this.state.currentInstitutionReferral.institutionId;
        if (!institutionId) {
            return;
        }

        const offerId = this.state.currentInstitutionReferral.offerId;
        const offerRecord = this.props.institutionReferrals
            .find((cr) => cr.institution_id === institutionId)
            .offers.find((or) => or.offer_id === offerId);
        let refId;
        if (offerRecord.referral) {
            refId = offerRecord.referral.id;
        }
        // User already has referral, display change and delete controls
        if (refId) {
            return (
                <Form>
                    <ControlledInputField
                        id="link"
                        displayName="Change Your Link / Referral Code"
                        inputType="text"
                        value={this.state.currentInstitutionReferral.link}
                        onChange={(id, value) => this.updateInstitutionRefLink(value)}
                    />
                    <Button
                        onClick={(event) => {
                            this.submitInstitutionReferral(event, () =>
                                this.props.updateInstitutionReferral(
                                    refId,
                                    offerRecord.referral.r[InstitutionReferral.f.link.key],
                                    this.state.currentInstitutionReferral.link,
                                ),
                            );
                        }}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={(event) => {
                            this.submitInstitutionReferral(event, () => this.props.deleteInstitutionReferral(refId));
                            this.updateInstitutionRefLink('');
                        }}
                        style={{ marginLeft: '20px' }}
                    >
                        Delete Your Referral
                    </Button>
                </Form>
            );
        } else {
            return (
                <Form>
                    <ControlledInputField
                        id="link"
                        displayName="Add Your Link / Referral Code"
                        inputType="url"
                        value={this.state.currentInstitutionReferral.link}
                        onChange={(id, value) => this.updateInstitutionRefLink(value)}
                    />
                    <Button
                        onClick={(event) => {
                            this.submitInstitutionReferral(event, () =>
                                this.props.addInstitutionReferral(offerId, this.state.currentInstitutionReferral.link),
                            );
                        }}
                    >
                        Submit
                    </Button>
                </Form>
            );
        }
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    return state.userProfile;
}

export default withRouter(
    connect(mapStateToProps, {
        fetchUser,
        fetchUserCreditCardReferrals,
        fetchUserInstitutionReferrals,
        editUserProfile,
        addCreditCardReferral,
        updateCreditCardReferral,
        deleteCreditCardReferral,
        addInstitutionReferral,
        updateInstitutionReferral,
        deleteInstitutionReferral,
        cleanUpUserProfile,
    })(UserProfileView),
);
