// @flow

import { jsonGetRequest } from '../requests';
import { setMessageAction } from '../messages/actions';
import type { Suggestions } from '../../entities/types';
import CreditCard from '../../entities/models/CreditCard';
import type { CardReferralsCleanUp, CardReferralsReceiveCard, CardReferralsReceiveList } from './types';
import CreditCardReferral from '../../entities/models/CreditCardReferral';

function receiveCard(response: { entity: Object, suggestions: Suggestions }): CardReferralsReceiveCard {
    return {
        type: 'cardReferrals/receiveCard',
        card: new CreditCard(response.entity),
        cardSuggestions: response.suggestions,
    };
}

export function fetchCard(id: string) {
    return (dispatch) => {
        jsonGetRequest(CreditCard.endpoints.info(id))
            .then((value) => dispatch(receiveCard(value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

function receiveReferralList(response: {
    data: { top_offer_id: string, offers: [{ offer_id: string, referrals: Array<Object> }] },
    suggestions: {},
}): CardReferralsReceiveList {
    // Map referrals to records
    response.data.offers.forEach((o) => {
        o.referrals = o.referrals.map((r) => new CreditCardReferral(r));
    });
    return {
        type: 'cardReferrals/receiveList',
        referralData: response.data,
        referralSuggestions: response.suggestions,
    };
}

export function fetchReferralList(card: CreditCard) {
    return (dispatch) => {
        const listPromise = jsonGetRequest(CreditCardReferral.endpoints.listByCard(card.id));

        listPromise
            .then((value) => dispatch(receiveReferralList(value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function cleanUpCardReferrals(): CardReferralsCleanUp {
    return {
        type: 'cardReferrals/cleanUp',
    };
}
