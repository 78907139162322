// @flow

import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import type { StoredEntity } from '../../../entities/abstract/StoredEntity';

type PropsType = {
    id: string,
    displayFormGroup: boolean,
    displayName: string,
    initialValue: [StoredEntity],
    options: [StoredEntity],
    maxValues: number,
    onChange: ([StoredEntity]) => void,
};

class TypeaheadInputField extends Component<PropsType> {
    static defaultProps = {
        maxValues: -1,
        displayFormGroup: true,
    };

    onEntitiesUpdated(newEntities) {
        const entities = [];
        for (const entity of newEntities) {
            // Check if it is not a duplicate
            const ids = entities.map((e) => e.id);
            if (!ids.includes(entity.id)) {
                entities.push(entity);
            }
        }
        if (this.props.maxValues < 1) {
            this.props.onChange(this.props.id, entities);
        } else {
            this.props.onChange(this.props.id, entities.slice(0, this.props.maxValues));
        }
    }

    render() {
        const typeahead = (
            <Typeahead
                id={this.props.id}
                selected={this.props.initialValue}
                labelKey={(option) => `${option.display_name}`}
                minLength={1}
                multiple
                onChange={(newEntities) => this.onEntitiesUpdated(newEntities)}
                promptText={'Start typing for suggestions...'}
                options={this.props.options}
            />
        );

        return this.props.displayFormGroup ? (
            <FormGroup key={this.props.id}>
                <Label for={this.props.id}>{this.props.displayName}</Label>
                {typeahead}
            </FormGroup>
        ) : (
            typeahead
        );
    }
}

export default TypeaheadInputField;
