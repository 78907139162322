import React, { Component } from 'react';
import type { QueryList, SortParams, Suggestions, ValueToDisplayNameMap } from '../../entities/types';
import CreditCard from '../../entities/models/CreditCard';
import { booleanVtDM, getActionButton, getRandomString } from '../utils';
import LoadingContainer from '../basicElements/LoadingContainer';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { cleanUpCardList, fetchCardList } from '../../store/cardList/actions';
import { buildUrl } from '../../store/requests';
import CheckboxFilterGroup from '../basicElements/controls/CheckboxFilterGroup';
import Institution from '../../entities/models/Institution';
import '../../styles/cardList.css';

//
// IMAGES
//

import canadaLogo from '../../res/canada.png';
import usLogo from '../../res/united-states.png';
import worldLogo from '../../res/world.png';

//
// PROPS
//

type PropsType = {
    // INJECTED FUNCTIONS
    fetchCardList: (queryList: ?QueryList, sortParams: ?SortParams) => void,
    cleanUpCardList: () => void,
    // STATE
    list: [CreditCard],
    count: number,
    suggestions: Suggestions,
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {
    queryMenuIsOpen: boolean,
    queries: QueryList,
    sort: SortParams,
};

//
// FILTER
//

type FilterControl = {
    name: string,
    fieldComponent: Component,
    modesComponent: Component,
};

//
// CLASS
//

class CardListView extends Component<PropsType, StateType> {
    //
    // INIT
    //

    constructor(props) {
        super(props);

        //
        // SETUP QUERY & SORTING
        //

        // The liw5 of fields to display in the liw5/rows
        this.f = {};

        // A helper collection of all queryable fields
        this.filterFields = {};
        this.sortFields = {};

        // Default state
        this.state = {
            queryMenuIsOpen: false,
            queries: {},
            sort: { ...CreditCard.defaultSort },
        };

        // Get the fields which can be searched
        // Assign the default values to each filter value and mode
        // Setup the helper field collection
        Object.entries(CreditCard.f).forEach(([name, field]) => {
            if (field.displayOnMain) {
                this.f[name] = field;

                if (field.supportsQuery) {
                    this.filterFields[name] = field;
                    this.state.queries[name] = {
                        value: [],
                        mode: 'in',
                        field,
                    };
                }

                if (field.supportsSort) {
                    this.sortFields[name] = field;
                }
            }
        });
    }

    //
    // FETCH
    //

    fetch(includeQueries: boolean = true, includeSort: boolean = true) {
        this.props.cleanUpCardList();

        // We initialize the sorting object with undefined values
        // Check if they're still undefined
        const sort = includeSort && this.state.sort.field !== undefined && this.state.sort.fieldName !== undefined;

        this.props.fetchCardList(includeQueries ? this.state.queries : null, sort ? this.state.sort : null);
    }

    //
    // COMPONENT LIFECYCLE
    //

    componentDidMount() {
        // Fetch the first page
        this.fetch(false);

        // SEO
        if (process.env.REACT_APP_SEO) {
            document.querySelectorAll('.seo-meta').forEach((e) => e.remove());
            document.head.innerHTML =
                document.head.innerHTML +
                '<title class="seo-meta">ChurningCanada Referrals - Best credit card offers in Canada</title>' +
                '<meta class="seo-meta" name="title" content="ChurningCanada Referrals - Best credit card offers in Canada">' +
                '<meta class="seo-meta" name="description" content="ChurningCanada Referrals provides the best credit card referral offers in Canada. We help you discover the best credit cards - maximum rewards, more savings.">' +
                '<meta class="seo-meta" property="og:type" content="website">' +
                '<meta class="seo-meta" property="og:url" content="https://referrals.churningcanada.com/">' +
                '<meta class="seo-meta" property="og:title" content="ChurningCanada Referrals - Best credit card offers in Canada">' +
                '<meta class="seo-meta" property="og:description" content="ChurningCanada Referrals provides the best credit card referral offers in Canada. We help you discover the best credit cards - maximum rewards, more savings.">' +
                '<meta class="seo-meta" property="og:image" content="">' +
                '<meta class="seo-meta" property="twitter:card" content="summary_large_image">' +
                '<meta class="seo-meta" property="twitter:url" content="https://referrals.churningcanada.com/">' +
                '<meta class="seo-meta" property="twitter:title" content="ChurningCanada Referrals - Best credit card offers in Canada">' +
                '<meta class="seo-meta" property="twitter:description" content="ChurningCanada Referrals provides the best credit card referral offers in Canada. We help you discover the best credit cards - maximum rewards, more savings.">' +
                '<meta class="seo-meta" property="twitter:image" content="">';
        }
    }

    componentWillUnmount() {
        // Clean up the state
        this.props.cleanUpCardList();
    }

    //
    // FILTER COMPONENTS
    //

    onCheckboxChange(id: string, value: String) {
        const checkedValues = this.state.queries[id].value;
        if (checkedValues.includes(value)) {
            this.state.queries[id].value = checkedValues.filter((c) => c !== value);
            // this.setState({
            //     ...this.state,
            //     queries: {
            //         ...this.state.queries,
            //         [id]: {
            //             ...this.state.queries[id],
            //             value: checkedValues.filter((c) => c !== value),
            //         },
            //     },
            // });
        } else {
            checkedValues.push(value);
        }

        this.fetch();
    }

    //
    // SEARCH FIELDS
    //

    getSearchControls(): [FilterControl] {
        const fields = [];

        Object.entries(this.filterFields).forEach(([name, field]) => {
            // If the fields is not of a types below or is an array, skip it
            if (!['str', 'num', 'ref', 'bool', 'enum'].includes(field.dataType.primitive) || field.dataType.isArray) {
                return;
            }

            let data: ValueToDisplayNameMap;

            switch (field.dataType.primitive) {
                case 'str':
                case 'num': {
                    const allValues = this.props.list.map((c) => c.r[field.key]);
                    data = allValues.map((v) => {
                        return { [v]: v };
                    });
                    break;
                }
                case 'ref': {
                    const allSuggestions = this.props.suggestions[field.dataType.extraData.model.classInternalName];
                    data = {};
                    allSuggestions.forEach((s) => {
                        data[s.id] = s.display_name;
                    });
                    break;
                }
                case 'enum': {
                    data = field.dataType.extraData;
                    break;
                }
                case 'bool': {
                    data = booleanVtDM;
                    break;
                }
                default:
                    data = null;
            }

            if (data != null) {
                const control = (
                    <CheckboxFilterGroup
                        key={getRandomString()}
                        id={name}
                        displayName={field.displayName}
                        data={data}
                        checkedValues={this.state.queries[name].value}
                        onChange={this.onCheckboxChange.bind(this)}
                    />
                );
                fields.push(control);
            }
        });

        return fields;
    }

    //
    // RENDER
    //

    render() {
        if (this.props.suggestions === undefined || this.props.list === undefined || this.props.count === undefined) {
            // If any of the network resources are not here, display a loading indicator
            return <LoadingContainer />;
        }

        const cards = this.props.list.map((c) => {
            const cardLogoEndpoint = buildUrl(CreditCard.endpoints.file(c.id, CreditCard.fileFields.photo.fileName));

            const institutionId = c.r[CreditCard.f.institutionId.key];

            let geographyLogo;
            let myInstitution = this.props.suggestions[Institution.classInternalName].find(
                (e) => e.id === institutionId,
            );
            switch (myInstitution[Institution.f.geography.key]) {
                case 1:
                    geographyLogo = canadaLogo;
                    break;
                case 4:
                    geographyLogo = usLogo;
                    break;
                case 5:
                    geographyLogo = worldLogo;
                    break;
                default:
                    break;
            }

            return (
                <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                    key={getRandomString()}
                    onClick={() => this.props.history.push(`/cards/${c.id}/referrals`)}
                >
                    <div className="card-container">
                        <img src={cardLogoEndpoint} alt="logo" className="card-logo" />
                        <div className="card-label">
                            <img src={geographyLogo} alt="geography-logo" className="card-geo-logo" />
                            <span className="mb-0">{c.r[CreditCard.f.name.key]}</span>
                        </div>
                    </div>
                </Col>
            );
        });

        // Get the search controls and arrange them properly
        const searchFields = this.getSearchControls().map((filterControl) => (
            <div className="search-control" key={getRandomString()}>
                {filterControl}
            </div>
        ));

        const searchFieldsContainer = <div className="search-controls">{searchFields}</div>;

        const showHideFiltersButton = getActionButton(
            this.state.queryMenuIsOpen ? 'Hide Filters' : 'Show Filters',
            () => this.setState({ queryMenuIsOpen: !this.state.queryMenuIsOpen }),
        );

        // Display a message if search is not supported, or the search fields and the search button
        const searchAndSortComponent = (
            <div>
                <div className="search-bar pt-2 pb-2">
                    <div className="search-bar-ref-selectors">
                        <div className="search-bar-ref-selector">
                            <input
                                type="radio"
                                className="search-bar-ref-selector"
                                name="ref-type"
                                id="ref-type-cards"
                                value="Show Credit Card Referrals"
                                defaultChecked
                            />
                            <label htmlFor="ref-type-cards">
                                <span role="img" aria-label="Cards">
                                    💳
                                </span>{' '}
                                Cards
                            </label>
                        </div>
                        <div className="search-bar-ref-selector">
                            <input
                                type="radio"
                                name="ref-type"
                                id="ref-type-others"
                                value="Show Other Referrals"
                                onChange={() => this.props.history.push('/institutions')}
                            />
                            <label htmlFor="ref-type-others">
                                <span role="img" aria-label="Other Referrals">
                                    💸
                                </span>{' '}
                                Other Referrals
                            </label>
                        </div>
                    </div>
                    {showHideFiltersButton}
                </div>
                {this.state.queryMenuIsOpen ? searchFieldsContainer : null}
            </div>
        );

        const header =
            cards.length > 0 ? (
                <h3 className="text-center mt-5 separated-text">Credit Cards</h3>
            ) : (
                <h6 className="text-center mt-5">There are no cards for the filter criteria you selected</h6>
            );

        // Create the grid
        return (
            <div>
                {searchAndSortComponent}
                <Container>
                    {header}
                    <Row>{cards}</Row>
                </Container>
            </div>
        );
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    return state.cardList;
}

export default withRouter(connect(mapStateToProps, { fetchCardList, cleanUpCardList })(CardListView));
