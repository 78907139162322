// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import { idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { AscendingSortOrder } from '../types';
import { constructEndpoints } from '../helpers';

class RewardProgram implements StoredEntity {
    static classInternalName = 'reward_program';

    static f: EntityFieldCollection = {
        id: idField,
        name: {
            displayName: 'Name',
            key: 'name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        type: {
            displayName: 'Type',
            key: 'program_type',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: {
                    1: 'Travel',
                    2: 'Cash',
                    3: 'Hybrid',
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = {
        fieldName: 'name',
        field: RewardProgram.f.name,
        order: AscendingSortOrder,
    };

    get id() {
        return this.r[RewardProgram.f.id.key];
    }

    get displayName() {
        return this.r[RewardProgram.f.name.key];
    }

    static endpoints: Endpoints = constructEndpoints('reward-programs');

    constructor(record: any) {
        this.r = record;
    }
}

export default RewardProgram;
