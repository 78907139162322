// @flow

import type { RedditLoginState, RedditLoginAction } from './types';

function getInitialRedditLoginState(): RedditLoginState {
    return {
        uri: undefined,
        status: undefined,
    };
}

// eslint-disable-next-line import/prefer-default-export
export function reduceRedditLogin(
    state: RedditLoginState = getInitialRedditLoginState(),
    action: RedditLoginAction,
): RedditLoginState {
    switch (action.type) {
        case 'redditLogin/requestRedditUri':
            return { ...state, status: 'pending' };

        case 'redditLogin/receiveRedditUri':
            return { ...state, status: 'ok', uri: action.uri };

        default:
            return state;
    }
}
