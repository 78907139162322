// @flow

import React, { Component } from 'react';
import MessageWrapper from './messages/MessageWrapper';
import SiteNavbar from './SiteNavbar';
import { Container } from 'reactstrap';
import User from '../../entities/models/User';

const GenericPage = ({ component, governor }: { component: Component, governor: User }): Component => (
    <div>
        <SiteNavbar governor={governor} />
        <MessageWrapper>{component}</MessageWrapper>
        <footer>
            <Container fluid>&copy; churningcanada.com</Container>
        </footer>
    </div>
);

export default GenericPage;
