// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import { createdAtField, getCreatedAtSort, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints } from '../helpers';

class User implements StoredEntity {
    static classInternalName = 'user';

    static f: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        name: {
            displayName: 'Name',
            key: 'name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        userId: {
            displayName: 'User ID',
            key: 'user_id',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        email: {
            displayName: 'Email',
            key: 'email',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            key: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        isTeam: {
            displayName: 'Is Team',
            key: 'is_team',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        redditId: {
            displayName: 'Reddit ID',
            key: 'reddit_id',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: true,
            displayOnMain: true,
        },
        redditMinRefScore: {
            displayName: 'Minimum Subreddit Karma',
            key: 'reddit_min_ref_score',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        redditMinAgeDays: {
            displayName: 'Minimum Reddit Account Age (Days)',
            key: 'reddit_min_age_days',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        redditScoreDays: {
            displayName: 'Reddit Score Computing Days',
            key: 'reddit_score_days',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        isRanked: {
            displayName: 'Is Being Ranked',
            key: 'is_ranked',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort('created_at');

    get id() {
        return this.r[User.f.id.key];
    }

    get displayName() {
        return this.r[User.f.email.key];
    }

    static endpoints: Endpoints = {
        ...constructEndpoints('users'),
        me: () => 'users/me',
    };

    constructor(record: any) {
        this.r = record;
    }
}

export default User;
