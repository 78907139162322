// @flow

import { jsonGetRequest } from '../requests';
import { setMessageAction } from '../messages/actions';
import type { InstitutionListCleanUp, InstitutionListReceive } from './types';
import Institution from '../../entities/models/Institution';

function receiveInstitutionList(
    response: { list: Array<Object>, suggestions: {} },
    count: any,
): InstitutionListReceive {
    return {
        type: 'institutionReferrals/receive',
        list: response.list.map((record) => new Institution(record)),
        count: count.count,
        suggestions: response.suggestions,
    };
}

export function fetchInstitutionList() {
    return (dispatch) => {
        const query = {
            referralsEnabled: {
                mode: 'eq',
                value: 1,
                field: Institution.f.referralsEnabled,
            },
        };

        const listPromise = jsonGetRequest(Institution.endpoints.list(query, null, null));
        const countPromise = jsonGetRequest(Institution.endpoints.count(query));

        Promise.all([listPromise, countPromise])
            .then((values) => dispatch(receiveInstitutionList(values[0], values[1])))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function cleanUpInstitutionList(): InstitutionListCleanUp {
    return {
        type: 'institutionReferrals/cleanUp',
    };
}
