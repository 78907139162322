// @flow

import type { Endpoints, EntityFieldCollection, PaginationParams, SortParams } from '../types';
import { createdAtField, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import { constructEndpoints, processPaginationParams, processSortParams } from '../helpers';
import { AscendingSortOrder } from '../types';
import Institution from './Institution';
import RewardProgram from './RewardProgram';
import { encodeQueryData } from '../../store/requests';

class CreditCard implements StoredEntity {
    static classInternalName = 'credit_card';

    static f: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        institutionId: {
            displayName: 'Institution',
            key: 'institution_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: Institution,
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        shortId: {
            displayName: 'Short ID',
            key: 'short_id',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: false,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        rewardProgramId: {
            displayName: 'Reward Program',
            key: 'reward_program_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: RewardProgram,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        name: {
            displayName: 'Name',
            key: 'name',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: false,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        network: {
            displayName: 'Network',
            key: 'network',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: {
                    1: 'Amex',
                    2: 'Visa',
                    3: 'MasterCard',
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {
        photo: {
            displayName: 'Photo',
            fileType: 'img',
            fileName: `photo`,
            displayOnMain: true,
        },
    };

    static defaultSort: SortParams = {
        fieldName: 'name',
        field: Institution.f.name,
        order: AscendingSortOrder,
    };

    get id() {
        return this.r[CreditCard.f.id.key];
    }

    get displayName() {
        return this.r[CreditCard.f.name.key];
    }

    static endpoints: Endpoints = {
        ...constructEndpoints('credit-cards'),
        list: (query: string, sortParams: ?SortParams, paginationParams: ?PaginationParams) => {
            const args = {};

            if (query) {
                args.query = query;
            }

            if (sortParams) {
                args.sort = processSortParams(sortParams);
            }

            if (paginationParams) {
                args.pagination = processPaginationParams(paginationParams);
            }

            return `credit-cards/all?${encodeQueryData(args)}`;
        },
    };

    constructor(record: any) {
        this.r = record;
    }
}

export default CreditCard;
