// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import AuthToken from '../../entities/AuthToken';
import StyleClasses from '../../styles/StyleClasses';
import User from '../../entities/models/User';
import type { RequestStatus } from '../../store/requests';
import { connect } from 'react-redux';
import { fetchRedditUri } from '../../store/redditLogin/actions';

//
// PROPS
//

type PropsType = {
    // PROPS
    governor: ?User,
    // STATE
    redditLoginUri: string,
    redditLoginUriFetchStatus: RequestStatus,
    // ROUTER
    history: any,
    // INJECTED FUNCTIONS
    fetchRedditUri: () => void,
};

//
// STATE
//

type StateType = {
    menuIsOpen: boolean,
};

//
// CLASS
//

class SiteNavbar extends Component<PropsType, StateType> {
    constructor(props: PropsType) {
        super(props);
        this.state = {
            menuIsOpen: false,
        };
    }

    toggleNavbar() {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen,
        });
    }

    componentDidUpdate(prevProps: PropsType) {
        // The request went thru okay
        if (prevProps.redditLoginUriFetchStatus === 'pending' && this.props.redditLoginUriFetchStatus === 'ok') {
            window.location.href = this.props.redditLoginUri;
        }
    }

    render() {
        const items = [
            <NavItem key="stats">
                <NavLink onClick={() => this.props.history.push('/stats')}>
                    <span className="nav-link">Leaderboard</span>
                </NavLink>
            </NavItem>,
            <NavItem key="faq">
                <NavLink onClick={() => this.props.history.push('/faq')}>
                    <span className="nav-link">FAQ</span>
                </NavLink>
            </NavItem>,
        ];

        if (this.props.governor) {
            items.push(
                <NavItem key="profile">
                    <NavLink onClick={() => this.props.history.push('/profile')}>
                        <span className="nav-link">My Profile & Referrals</span>
                    </NavLink>
                </NavItem>,
            );
            items.push(
                <NavItem key="logout">
                    <NavLink
                        onClick={() => {
                            AuthToken.unset();
                            this.props.history.push('/');
                            window.location.reload();
                        }}
                    >
                        <span className="nav-link">Logout</span>
                    </NavLink>
                </NavItem>,
            );
        } else {
            items.push(
                <NavItem key="login">
                    <NavLink onClick={() => this.props.fetchRedditUri()}>
                        <span className="nav-link">Login with Reddit</span>
                    </NavLink>
                </NavItem>,
            );
        }

        return (
            <Navbar color="dark" expand="md">
                <NavbarBrand href="/" className={StyleClasses.plainWhiteText}>
                    CC Referrals <sup>β</sup>
                </NavbarBrand>
                <NavbarToggler onClick={() => this.toggleNavbar()} />

                <Collapse isOpen={this.state.menuIsOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {items}
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    return {
        redditLoginUri: state.redditLogin.uri,
        redditLoginUriFetchStatus: state.redditLogin.status,
    };
}

export default withRouter(connect(mapStateToProps, { fetchRedditUri })(SiteNavbar));
