// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import { createdAtField, getCreatedAtSort, idField } from '../abstract/StoredEntity';
import type { StoredEntity } from '../abstract/StoredEntity';
import User from './User';
import UrlClick from './UrlClick';
import InstitutionOffer from './InstitutionOffer';

class InstitutionReferral implements StoredEntity {
    static classInternalName = 'institution_referral';

    static f: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        offerId: {
            displayName: 'Offer',
            key: 'offer_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: InstitutionOffer,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        userId: {
            displayName: 'User',
            key: 'user_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: User,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: true,
        },
        link: {
            displayName: 'Link',
            key: 'link',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            key: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        clicks: {
            displayName: 'Clicks',
            key: 'clicks',
            dataType: {
                primitive: 'simple-entity',
                isArray: true,
                extraData: {
                    model: UrlClick,
                },
            },
            supportsQuery: false,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: false,
            isOptional: false,
            displayOnMain: false,
        },
        expiration: {
            displayName: 'Expiration',
            key: 'expiration',
            dataType: {
                primitive: 'ts',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: undefined,
            isOptional: false,
            displayOnMain: true,
        },
        clicksOneDay: {
            displayName: 'Clicks Last 24H',
            key: 'clicks_one_day',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
        clicksThirtyDays: {
            displayName: 'Clicks Last 30D',
            key: 'clicks_thirty_days',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: undefined,
            canEdit: true,
            isOptional: true,
            displayOnMain: true,
        },
    };

    static fileFields: EntityFieldCollection = {};

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.r[InstitutionReferral.f.id.key];
    }

    get displayName() {
        return this.r[InstitutionReferral.f.id.key];
    }

    static endpoints: Endpoints = {
        listByInstitution: (institutionId) => `institution-referrals/all/institution/${institutionId}`,
        my: () => `institution-referrals/my`,
        info: (referralId) => `institution-referrals/one/${referralId}`,
        stats: () => `institution-referrals/stats`,
        link: (referralId) => `institution-referrals/one/${referralId}/link`,
    };

    constructor(record: any) {
        this.r = record;
    }
}

export default InstitutionReferral;
