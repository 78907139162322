// @flow

// Geography

export const Geography = {
    1: 'Canada',
    2: 'QC',
    3: 'ROC',
    4: 'USA',
    5: 'World',
};

// Value to display names

export type FilterMode = 'regex' | 'eq' | 'gt' | 'lt' | 'in';

export type ValueToDisplayNameMap = {
    [displayName: string | number | FilterMode]: string,
};

// Field values

type RefExtraData = {
    maxValues: number,
    model: Class,
};

type EnumExtraData = ValueToDisplayNameMap;

type SimpleEntityExtraData = {
    model: Class,
};

type ExtraData = RefExtraData | EnumExtraData | SimpleEntityExtraData | null;

export type FieldType = {
    primitive: 'str' | 'num' | 'ref' | 'ts' | 'enum' | 'bool' | 'simple-entity',
    isArray: boolean,
    extraData: ExtraData,
};

// Field types

export type SimpleEntityField = {
    displayName: string,
    key: string,
    dataType: FieldType,
    isOptional: boolean,
};

export type StoredEntityField = {
    displayName: string,
    key: string,
    dataType: FieldType,
    supportsQuery: boolean,
    supportsSort: boolean,
    needToCreate: boolean,
    canEdit: boolean,
    isOptional: boolean,
    displayOnMain: boolean,
};

export type FileType = 'img';

export type FileField = {
    displayName: string,
    fileType: FileType,
    fileName: string,
    displayOnMain: boolean,
};

export interface EntityFieldCollection {
    [key: string]: StoredEntityField | SimpleEntityField | FileField;
}

// Queries

export type QueryParams = {
    field: StoredEntityField,
    query: string,
}[];

export type QueryList = {
    [displayName: string]: {
        mode: FilterMode,
        value: any,
        field: StoredEntityField,
    },
};

// Sorting

export const AscendingSortOrder = 'asc';
export const DescendingSortOrder = 'desc';

export type SortOrder = AscendingSortOrder | DescendingSortOrder; // 1 for ascending, 0 for descending

export type SortParams = {
    field: StoredEntityField,
    order: SortOrder,
};

// Pagination

export type PaginationParams = {
    page: number,
    perPage: number,
};

// Suggestions

export type Suggestion = {
    id: string,
    display_name: string,
    [key: string]: any,
};

export type Suggestions = {
    [key: string]: [Suggestion],
};

// Endpoints

export type Endpoints = {
    list: ((QueryParams, SortParams, PaginationParams) => string) | undefined,
    suggestions: (() => string) | undefined,
    count: ((QueryParams) => string) | undefined,
    info: ((id: string) => string) | undefined,
    file: ((id: string, fileName: string) => string) | undefined,
    [key: string]: string,
};
