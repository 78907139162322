// @flow

import type { CardListState, CardListAction } from './types';

export function getDefaultCardListState(): CardListState {
    return {
        list: undefined,
        count: undefined,
        suggestions: undefined,
    };
}

export function reduceCardList(
    state: CardListState = getDefaultCardListState(),
    action: CardListAction,
): CardListState {
    switch (action.type) {
        case 'cardList/receive':
            return {
                ...state,
                list: action.list,
                count: action.count,
                suggestions: action.suggestions,
            };

        case 'cardList/cleanUp':
            return getDefaultCardListState();

        default:
            return state;
    }
}
