class AuthToken {
    /*
     * Provides an abstraction over the token storage mechanism.
     */

    static get key() {
        return 'token';
    }

    static get() {
        return window.localStorage.getItem(AuthToken.key);
    }

    static set(value) {
        window.localStorage.setItem(AuthToken.key, value);
    }

    static unset() {
        window.localStorage.removeItem(AuthToken.key);
    }
}

export default AuthToken;
