// @flow

import type { RedditAccountConfirmationState, RedditAccountConfirmationAction } from './types';
import AuthToken from '../../entities/AuthToken';

function getInitialRedditAccountConfirmationState(): RedditAccountConfirmationState {
    return {
        status: undefined,
        hasError: false,
    };
}

// eslint-disable-next-line import/prefer-default-export
export function reduceRedditAccountConfirmation(
    state: RedditAccountConfirmationState = getInitialRedditAccountConfirmationState(),
    action: RedditAccountConfirmationAction,
): RedditAccountConfirmationState {
    switch (action.type) {
        case 'redditAccountConfirmation/startLoading':
            return { ...state, status: 'pending' };

        case 'redditAccountConfirmation/emailCheckNeeded':
            return { ...state, status: 'email-check-needed', hasError: false };

        case 'redditAccountConfirmation/otpNeeded':
            return { ...state, status: 'otp-needed', hasError: false };

        case 'redditAccountConfirmation/error':
            return { ...state, status: action.prevStatus, hasError: true };

        case 'redditAccountConfirmation/receiveToken':
            AuthToken.set(action.token);
            return { ...state, status: 'ok', hasError: false };

        default:
            return state;
    }
}
