// @flow

import type { Endpoints, EntityFieldCollection, SortParams, StoredEntityField } from '../types';
import { AscendingSortOrder } from '../types';

export const idField: StoredEntityField = {
    displayName: 'ID',
    key: 'id',
    dataType: {
        primitive: 'str',
        isArray: false,
        extraData: null,
    },
    supportsQuery: false,
    supportsSort: false,
    needToCreate: false,
    canEdit: false,
    isOptional: false,
    displayOnMain: true,
};

export const createdAtField: StoredEntityField = {
    displayName: 'Created At',
    key: 'created_at',
    dataType: {
        primitive: 'ts',
        isArray: false,
        extraData: null,
    },
    supportsQuery: false,
    supportsSort: false,
    needToCreate: false,
    canEdit: false,
    isOptional: false,
    displayOnMain: true,
};

export function getCreatedAtSort(fieldName: string): SortParams {
    return {
        field: createdAtField,
        fieldName,
        order: AscendingSortOrder,
    };
}

export interface StoredEntity {
    f: EntityFieldCollection; // static
    fileFields: EntityFieldCollection; // static
    defaultSort: SortParams; // static
    endpoints: Endpoints; // static
    constructor(record: any): StoredEntity; // static
    classInternalName: string; // static

    displayName: string;
    id: string;

    r: any;
}
