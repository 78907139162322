// @flow

import React, { Component } from 'react';
import '../../styles/faq.css';

//
// PROPS
//

type PropsType = {
    // DATA
    qa: { name: string, acceptedAnswer: { text: string } },
};

//
// STATE
//

type StateType = {};

//
// CLASS
//

class FaqEntry extends Component<PropsType, StateType> {
    render() {
        return (
            <div className="faq-entry">
                <div className="faq-question">{this.props.qa.name}</div>
                <div className="faq-answer">{this.props.qa.acceptedAnswer.text}</div>
            </div>
        );
    }
}

//
// ROUTER
//

export default FaqEntry;
