// @flow

import type { RefStatsState, RefStatsAction } from './types';

export function getDefaultRefStatsState(): RefStatsState {
    return {
        data: undefined,
        suggestions: undefined,
    };
}

export function reduceRefStats(
    state: RefStatsState = getDefaultRefStatsState(),
    action: RefStatsAction,
): RefStatsState {
    switch (action.type) {
        case 'refStats/receiveData':
            return {
                ...state,
                data: action.data,
                suggestions: action.suggestions,
            };

        case 'cardReferrals/cleanUp':
            return getDefaultRefStatsState();

        default:
            return state;
    }
}
