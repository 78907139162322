// @flow

import { setMessageAction } from '../messages/actions';
import { buildUrl, getJsonContentTypeHeader, processFetchErrors } from '../requests';
import type {
    RedditAccountConfirmationEmailCheckNeeded,
    RedditAccountConfirmationError,
    RedditAccountConfirmationOtpNeeded,
    RedditAccountConfirmationReceiveToken,
    RedditAccountConfirmationStartLoading,
    RedditAccountConfirmationStatus,
} from './types';

//
// COMMONS
//

function startLoading(): RedditAccountConfirmationStartLoading {
    return {
        type: 'redditAccountConfirmation/startLoading',
    };
}

function hasError(prevStatus: RedditAccountConfirmationStatus): RedditAccountConfirmationError {
    return {
        type: 'redditAccountConfirmation/error',
        prevStatus,
    };
}

function receiveToken(token): RedditAccountConfirmationReceiveToken {
    return {
        type: 'redditAccountConfirmation/receiveToken',
        token,
    };
}

//
// REDDIT CONFIRMATION
//

function emailCheckNeeded(): RedditAccountConfirmationEmailCheckNeeded {
    return {
        type: 'redditAccountConfirmation/emailCheckNeeded',
    };
}

export function sendRedditConfirmation(ref, code) {
    return (dispatch) => {
        dispatch(startLoading());

        processFetchErrors(
            fetch(buildUrl('users/reddit/profile-check/confirm'), {
                method: 'POST',
                headers: getJsonContentTypeHeader(),
                body: JSON.stringify({ reddit_ref: ref, code }),
            }),
        )
            .then((json) => {
                // User is unknown, we need an email
                if (json.reddit_ref) {
                    dispatch(emailCheckNeeded());
                    // User is known, we got a token
                } else if (json.token) {
                    dispatch(receiveToken(json.token));
                }
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
                dispatch(hasError('email-check-needed'));
            });
    };
}

//
// EMAIL CHECK
//

function otpNeeded(): RedditAccountConfirmationOtpNeeded {
    return {
        type: 'redditAccountConfirmation/otpNeeded',
    };
}

export function requestEmailCheck(ref, email) {
    return (dispatch) => {
        dispatch(startLoading());

        processFetchErrors(
            fetch(buildUrl('users/reddit/email-check/request'), {
                method: 'POST',
                headers: getJsonContentTypeHeader(),
                body: JSON.stringify({ reddit_ref: ref, email }),
            }),
        )
            .then((json) => {
                dispatch(otpNeeded());
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
                dispatch(hasError('email-check-needed'));
            });
    };
}

export function sendEmailCode(ref, code) {
    return (dispatch) => {
        dispatch(startLoading());

        processFetchErrors(
            fetch(buildUrl('users/reddit/email-check/confirm'), {
                method: 'POST',
                headers: getJsonContentTypeHeader(),
                body: JSON.stringify({ reddit_ref: ref, code }),
            }),
        )
            .then((json) => {
                dispatch(receiveToken(json.token));
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
                dispatch(hasError('otp-needed'));
            });
    };
}
