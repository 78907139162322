// @flow

import type { MessagesState, MessagesAction } from './types';

const initialState: MessagesState = {
    message: undefined,
    messageType: undefined,
    timer: undefined,
};

// eslint-disable-next-line import/prefer-default-export
export function reduce(
    state: MessagesState = initialState,
    action: MessagesAction = { type: 'messages/clear' },
): MessagesState {
    switch (action.type) {
        case 'messages/clear':
            if (state.timer) {
                clearTimeout(state.timer);
            }
            return initialState;

        case 'messages/set': {
            if (state.timer) {
                clearTimeout(state.timer);
            }

            return { ...state, ...action };
        }

        default:
            return state;
    }
}
