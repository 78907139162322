// @flow

import {
    deleteRequest,
    filePutRequest,
    getFileRequestBody,
    getJsonRequestBody,
    jsonGetRequest,
    jsonPatchRequest,
    jsonPostRequest,
} from '../requests';
import { setMessageAction } from '../messages/actions';
import type { Suggestions } from '../../entities/types';
import type {
    UserProfileCleanUp,
    UserProfileReceiveUser,
    UserProfileReceiveUserCreditCardReferrals,
    UserProfileReceiveUserInstitutionReferrals,
} from './types';
import CreditCardReferral from '../../entities/models/CreditCardReferral';
import User from '../../entities/models/User';
import type { InternalFormData } from '../../ui/types';
import InstitutionReferral from '../../entities/models/InstitutionReferral';

function receiveUser(response: { entity: Object, suggestions: Suggestions }): UserProfileReceiveUser {
    return {
        type: 'userProfile/receiveUser',
        user: new User(response.entity),
        userSuggestions: response.suggestions,
    };
}

export function fetchUser() {
    return (dispatch) => {
        jsonGetRequest(User.endpoints.me())
            .then((value) => dispatch(receiveUser(value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

function receiveUserCreditCardReferrals(response: {
    data: [
        {
            card_id: string,
            offers: [{ offer_id: string, referral: ?Object }],
        },
    ],
    suggestions: Suggestions,
}): UserProfileReceiveUserCreditCardReferrals {
    // Map referral JSON to CreditCardReferral
    response.data.forEach((c) => {
        c.offers.forEach((o) => {
            o.referral = o.referral ? new CreditCardReferral(o.referral) : null;
        });
    });

    return {
        type: 'userProfile/receiveCardReferrals',
        cardReferrals: response.data,
        cardRefSuggestions: response.suggestions,
    };
}

export function fetchUserCreditCardReferrals() {
    return (dispatch) => {
        const refPromise = jsonGetRequest(CreditCardReferral.endpoints.my());

        refPromise
            .then((v) => dispatch(receiveUserCreditCardReferrals(v)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function addCreditCardReferral(offerId: string, link: string) {
    return (dispatch) => {
        const body = {
            offer_id: offerId,
            link: link,
        };

        jsonPostRequest(CreditCardReferral.endpoints.my(), getJsonRequestBody(body))
            .then(() => {
                dispatch(fetchUserCreditCardReferrals());
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
            });
    };
}

export function updateCreditCardReferral(referralId: string, oldLink: string, link: string) {
    return (dispatch) => {
        const body = {};
        // Only update link if it changed
        if (oldLink !== link) {
            body.link = link;
        }

        jsonPatchRequest(CreditCardReferral.endpoints.info(referralId), getJsonRequestBody(body))
            .then(() => {
                dispatch(setMessageAction('Your referral has been successfully updated', 'message'));
                // dispatch(fetchUser());
                dispatch(fetchUserCreditCardReferrals());
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
            });
    };
}

export function deleteCreditCardReferral(referralId: string, user: User) {
    return (dispatch) => {
        deleteRequest(CreditCardReferral.endpoints.info(referralId))
            .then(() => {
                dispatch(fetchUserCreditCardReferrals(user));
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
            });
    };
}

function receiveUserInstitutionReferrals(response: {
    data: [
        {
            institution_id: string,
            offers: [{ offer_id: string, referral: ?Object }],
        },
    ],
    suggestions: Suggestions,
}): UserProfileReceiveUserInstitutionReferrals {
    // Map referral JSON to InstitutionReferral
    response.data.forEach((c) => {
        c.offers.forEach((o) => {
            o.referral = o.referral ? new InstitutionReferral(o.referral) : null;
        });
    });

    return {
        type: 'userProfile/receiveInstitutionReferrals',
        institutionReferrals: response.data,
        institutionRefSuggestions: response.suggestions,
    };
}

export function fetchUserInstitutionReferrals() {
    return (dispatch) => {
        const refPromise = jsonGetRequest(InstitutionReferral.endpoints.my());

        refPromise
            .then((v) => dispatch(receiveUserInstitutionReferrals(v)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function addInstitutionReferral(offerId: string, link: string) {
    return (dispatch) => {
        const body = {
            offer_id: offerId,
            link: link,
        };

        jsonPostRequest(InstitutionReferral.endpoints.my(), getJsonRequestBody(body))
            .then(() => {
                dispatch(fetchUserInstitutionReferrals());
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
            });
    };
}

export function updateInstitutionReferral(referralId: string, oldLink: string, link: string) {
    return (dispatch) => {
        const body = {};
        // Only update link if it changed
        if (oldLink !== link) {
            body.link = link;
        }

        jsonPatchRequest(InstitutionReferral.endpoints.info(referralId), getJsonRequestBody(body))
            .then(() => {
                dispatch(setMessageAction('Your referral has been successfully updated', 'message'));
                // dispatch(fetchUser());
                dispatch(fetchUserInstitutionReferrals());
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
            });
    };
}

export function deleteInstitutionReferral(referralId: string, user: User) {
    return (dispatch) => {
        deleteRequest(InstitutionReferral.endpoints.info(referralId))
            .then(() => {
                dispatch(fetchUserInstitutionReferrals(user));
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
            });
    };
}

export function cleanUpUserProfile(): UserProfileCleanUp {
    return {
        type: 'userProfile/cleanUp',
    };
}

export function editUserProfile(entity: User, data: InternalFormData) {
    return (dispatch) => {
        const body = {};

        Object.entries(User.f).forEach(([name, field]) => {
            let value;

            if (field.isOptional && !data[name]) {
                value = null;
            } else {
                switch (field.dataType.primitive) {
                    case 'str':
                    case 'bool':
                        value = data[name];
                        break;
                    case 'enum': // TODO when becomes segmented control
                    case 'num':
                    case 'ts': // TODO when becomes date
                        value = Number(data[name]);
                        break;
                    case 'ref': {
                        if (data[name].length === 0) {
                            value = field.dataType.isArray ? [] : null;
                        } else {
                            const ids = data[name].map((e) => e.id);
                            value = field.dataType.isArray ? ids : ids[0];
                        }
                        break;
                    }
                    case 'simple-entity': {
                        try {
                            value = JSON.parse(data[name]);
                        } catch (e) {
                            dispatch(setMessageAction(`Invalid JSON in field ${field.name}`, 'error'));
                        }
                        break;
                    }
                    default:
                        break;
                }
            }

            // Comparing strings to account for arrays. This is OK since this function never has incoming objects.
            if (value !== undefined && JSON.stringify(value) !== JSON.stringify(entity.r[field.key])) {
                body[field.key] = value;
            }
        });

        const promises = [jsonPatchRequest(User.endpoints.me(), getJsonRequestBody(body))];

        Object.entries(User.fileFields).forEach(([name, field]) => {
            const value = data[name];
            if (value != null) {
                promises.push(filePutRequest(User.endpoints.file('me', field.fileName), getFileRequestBody(value)));
            }
        });

        Promise.all(promises)
            .then(() => {
                dispatch(setMessageAction('Profile updated', 'success'));
                dispatch(fetchUser());
                dispatch(fetchUserCreditCardReferrals(entity));
            })
            .catch((error) => {
                dispatch(setMessageAction(error.toString(), 'error'));
            });
    };
}
