// @flow

import React, { Component } from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { Redirect, Switch } from "react-router";
import { Button } from 'reactstrap';
import AuthToken from './entities/AuthToken';

import type { AppInitializationStatus } from './store/app/types';
import { fetchGovernor, setInitializationStatus } from './store/app/actions';
import LoadingContainer from './ui/basicElements/LoadingContainer';
import StyleClasses from './styles/StyleClasses';
import User from './entities/models/User';
import GenericPage from './ui/basicElements/GenericPage';
import RedditAccountConfirmationPage from './ui/accessControl/RedditAccountConfirmationPage';
import RedditLoginPage from './ui/accessControl/RedditLoginPage';
import CardListView from './ui/cardReferrals/CardListView';
import CardReferralsView from './ui/cardReferrals/CardReferralsView';
import { addScript, getRandomString } from "./ui/utils";
import UserProfileView from './ui/userProfile/UserProfileView';
import MessageWrapper from './ui/basicElements/messages/MessageWrapper';
import FaqView from "./ui/faq/FaqView";
import RefStatsView from "./ui/cardReferrals/RefStatsView";
import InstitutionListView from "./ui/institutionReferrals/InstitutionListView";
import InstitutionReferralsView from "./ui/institutionReferrals/InstitutionReferralsView";

//
// PROPS
//

type PropsType = {
    // STATE
    governor: User | undefined,
    initializationStatus: AppInitializationStatus,
    // ROUTER
    history: any,
    // INJECTED FUNCTIONS
    fetchGovernor: () => void,
    setInitializationStatus: (status: AppInitializationStatus) => void,
};

//
// STATE
//

type StateType = {
    errorAlert: Component,
    errorTimer: Number,
};

//
// CLASS
//

class App extends Component<PropsType, StateType> {
    token: string;

    constructor(props) {
        super(props);

        // Token

        // Not state because doesn't change within the lifecycle
        // When it gets reset the page resets too
        this.token = AuthToken.get();

        if (this.token) {
            this.props.fetchGovernor();
            this.props.setInitializationStatus('pending');
        } else {
            this.props.setInitializationStatus('authNeeded');
        }
    }

    componentDidUpdate(): * {
        const { governor } = this.props;

        if (this.props.initializationStatus === 'ok') {
            return;
        }

        if (governor !== undefined) {
            this.props.setInitializationStatus('ok');
            if (process.env.REACT_APP_HEAP_ID) {
                addScript({}, `heap.identify("${governor.id}")`)
            }
        }
    }

    commonRoutes() {
        return [
            <Route
                key={'stats'}
                exact
                path={'/stats'}
                component={() => (
                    <GenericPage
                        governor={this.props.governor}
                        component={<RefStatsView />}
                    />
                )}
            />,
            <Route
                key={'faq'}
                exact
                path={'/faq'}
                component={() => (
                    <GenericPage
                        governor={this.props.governor}
                        component={<FaqView />}
                    />
                )}
            />,
            <Route
                key={'card-refs'}
                exact
                path={'/cards/:id/referrals'}
                component={() => (
                    <GenericPage
                        governor={this.props.governor}
                        component={<CardReferralsView governor={this.props.governor} />}
                    />
                )}
            />,
            <Route
                key={'institution-refs'}
                exact
                path={'/institutions/:id/referrals'}
                component={() => (
                    <GenericPage
                        governor={this.props.governor}
                        component={<InstitutionReferralsView governor={this.props.governor} />}
                    />
                )}
            />,
            <Route
                key={'cards'}
                exact
                path={['/cards', '/']}
                component={() => <GenericPage governor={this.props.governor} component={<CardListView />} />}
            />,
            <Route
                key={'institutions'}
                exact
                path={['/institutions', '/']}
                component={() => <GenericPage governor={this.props.governor} component={<InstitutionListView />} />}
            />,
            <Route
                key={'404'}
                component={() => (
                    <GenericPage
                        governor={this.props.governor}
                        component={
                            <div className={StyleClasses.pageCenter}>
                                <h2 className={StyleClasses.bottomSpacedElement}>
                                    Sorry, we couldn't find this page :(
                                </h2>
                                <a href={'/cards'} className="box-text" key={getRandomString()}>
                                    Check out our cards
                                </a>
                            </div>
                        }
                    />
                )}
            />,
        ];
    }

    loggedInContent() {
        return (
            <Router>
                <Switch>
                    <Route exact path={'/reddit-cb'}>
                        <Redirect to='/' />
                    </Route>
                    <Route
                        exact
                        path={'/profile'}
                        component={() => <GenericPage governor={this.props.governor} component={<UserProfileView />} />}
                    />
                    {this.commonRoutes()}
                </Switch>
            </Router>
        );
    }

    loggedOutContent = () => (
        <Router>
            <Switch>
                <Route
                    exact
                    path={'/reddit-cb'}
                    component={() => <RedditAccountConfirmationPage />} />}
                />
                <Route exact path={'/login'} component={() => <MessageWrapper><RedditLoginPage /></MessageWrapper>} />} />
                {this.commonRoutes()}
            </Switch>
        </Router>
    );

    pendingContent = () => (
        <MessageWrapper>
            <div className={StyleClasses.centeredElement}>
                <LoadingContainer />
                <Button
                    className={StyleClasses.lotSpacedElement}
                    onClick={() => {
                        AuthToken.unset();
                        window.location.replace('/');
                    }}
                >
                    Logout
                </Button>
            </div>
        </MessageWrapper>
    );

    render() {
        switch (this.props.initializationStatus) {
            case 'authNeeded':
                return this.loggedOutContent();
            case 'pending':
                return this.pendingContent();
            case 'ok':
                return this.loggedInContent();
            default:
                return null;
        }
    }
}

function mapStateToProps(state) {
    return state.app;
}

export default connect(mapStateToProps, {
    fetchGovernor,
    setInitializationStatus,
})(App);
