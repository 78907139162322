import React, { Component } from 'react';
import type { QueryList, SortParams, Suggestions } from '../../entities/types';
import CreditCard from '../../entities/models/CreditCard';
import LoadingContainer from '../basicElements/LoadingContainer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { buildUrl } from '../../store/requests';
import CreditCardReferral from '../../entities/models/CreditCardReferral';
import RewardProgram from '../../entities/models/RewardProgram';
import CreditCardOffer from '../../entities/models/CreditCardOffer';
import User from '../../entities/models/User';
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { cleanUpCardReferrals, fetchCard, fetchReferralList } from '../../store/cardReferrals/actions';
import '../../styles/cardReferrals.css';
import Institution from '../../entities/models/Institution';
import type { ReferralData } from '../../store/cardReferrals/types';
import CardReferralApplyView from './CardReferralApplyView';

//
// IMAGES
//

import amexLogo from '../../res/amex.png';
import visaLogo from '../../res/visa.png';
import mcLogo from '../../res/mastercard.png';
import canadaLogo from '../../res/canada.png';
import usLogo from '../../res/united-states.png';
import rpTravelLogo from '../../res/rpTravel.png';
import rpCashLogo from '../../res/rpCash.png';
import rpHybridLogo from '../../res/rpHybrid.png';

//
// PROPS
//

type PropsType = {
    // PROPS
    governor: ?User,
    // INJECTED FUNCTIONS
    fetchCard: (string) => void,
    fetchReferralList: (queryList: ?QueryList, sortParams: ?SortParams) => void,
    cleanUpCardReferrals: () => void,
    // STATE
    card: CreditCard,
    referralData: ReferralData,
    referralSuggestions: Suggestions,
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {
    refModalOpen: boolean,
    refModalOfferId: string,
    refModalUsername: string,
    offerDetailsModalOpen: boolean,
    offerDetailsModalOfferId: string,
};

//
// CLASS
//

class CardReferralsView extends Component<PropsType, StateType> {
    cardId: string;

    //
    // INIT
    //

    constructor() {
        super();
        this.state = {
            refModalOpen: false,
            refModalOfferId: undefined,
            refModalUsername: undefined,
            offerDetailsModalOpen: false,
            offerDetailsModalOfferId: undefined,
        };
    }

    //
    // FETCH
    //

    fetch() {
        this.props.fetchCard(this.cardId);
    }

    //
    // COMPONENT LIFECYCLE
    //

    componentDidMount() {
        this.cardId = this.props.match.params.id;
        this.fetch();
    }

    componentDidUpdate(prevProps: PropsType) {
        if (prevProps.card === undefined && this.props.card) {
            this.props.fetchReferralList(this.props.card);
            const cardName = this.props.card.r[CreditCard.f.name.key];

            // SEO
            if (process.env.REACT_APP_SEO) {
                document.querySelectorAll('.seo-meta').forEach((e) => e.remove());
                document.head.innerHTML =
                    document.head.innerHTML +
                    `<title class="seo-meta">ChurningCanada Referrals - ${cardName}</title>` +
                    `<meta class="seo-meta" name="title" content="ChurningCanada Referrals - ${cardName}">` +
                    `<meta class="seo-meta" name="description" content="Best referral offer for ${cardName} in Canada - earn more welcome bonus and save on annual fees. ">` +
                    '<meta class="seo-meta" property="og:type" content="website">' +
                    `<meta class="seo-meta" property="og:url" content="${document.location.href}">` +
                    `<meta class="seo-meta" property="og:title" content="ChurningCanada Referrals - ${cardName}">` +
                    `<meta class="seo-meta" property="og:description" content="Best referral offer for ${cardName} in Canada - earn more welcome bonus and save on annual fees. ">` +
                    '<meta class="seo-meta" property="og:image" content="">' +
                    '<meta class="seo-meta" property="twitter:card" content="summary_large_image">' +
                    `<meta class="seo-meta" property="twitter:url" content="${document.location.href}">` +
                    `<meta class="seo-meta" property="twitter:title" content="ChurningCanada Referrals - ${cardName}">` +
                    `<meta class="seo-meta" property="twitter:description" content="Best referral offer for ${cardName} in Canada - earn more welcome bonus and save on annual fees. ">` +
                    '<meta class="seo-meta" property="twitter:image" content="">';
            }
        }
    }

    componentWillUnmount() {
        // Clean up the state
        this.props.cleanUpCardReferrals();
    }

    //
    // RENDER
    //

    getCardHeader() {
        const card = this.props.card;

        // Build the image endpoints
        const cardLogoEndpoint = buildUrl(CreditCard.endpoints.file(card.id, CreditCard.fileFields.photo.fileName));
        const institutionId = card.r[CreditCard.f.institutionId.key];
        const institutionLogoEndpoint = buildUrl(
            Institution.endpoints.file(institutionId, Institution.fileFields.logo.fileName),
        );

        // Network logo
        let networkLogo;
        switch (card.r[CreditCard.f.network.key]) {
            case 1:
                networkLogo = amexLogo;
                break;
            case 2:
                networkLogo = visaLogo;
                break;
            case 3:
                networkLogo = mcLogo;
                break;
            default:
                break;
        }

        // Geography logo
        let geographyLogo;
        let myInstitution = this.props.referralSuggestions[Institution.classInternalName].find(
            (e) => e.id === institutionId,
        );
        switch (myInstitution[Institution.f.geography.key]) {
            case 1:
                geographyLogo = canadaLogo;
                break;
            case 4:
                geographyLogo = usLogo;
                break;
            default:
                break;
        }

        // Program type logo
        let programTypeLogo;
        let myRp = this.props.referralSuggestions[RewardProgram.classInternalName].find(
            (e) => e.id === card.r[CreditCard.f.rewardProgramId.key],
        );
        switch (myRp[RewardProgram.f.type.key]) {
            case 1:
                programTypeLogo = rpTravelLogo;
                break;
            case 2:
                programTypeLogo = rpCashLogo;
                break;
            case 3:
                programTypeLogo = rpHybridLogo;
                break;
            default:
                break;
        }

        return (
            <div id="card-header">
                <img id="card-logo" src={cardLogoEndpoint} alt="card-logo" />
                <div id="card-name">
                    <img src={institutionLogoEndpoint} alt="institution-logo" />
                    <h2 className="mb-0">{card.r[CreditCard.f.name.key]}</h2>
                </div>
                <div id="card-data">
                    <div id="card-ntw">
                        <b>Network</b>
                        <img src={networkLogo} alt="network-logo" />
                    </div>
                    <div id="card-geography">
                        <b>Geography</b>
                        <img src={geographyLogo} alt="geography-logo" />
                    </div>
                    <div id="card-rptype">
                        <b>Rewards</b>
                        <img src={programTypeLogo} alt="rptype-logo" />
                    </div>
                </div>
            </div>
        );
    }

    toggleRefModal() {
        console.log(this.state);
        this.setState({
            refModalOpen: !this.state.refModalOpen,
        });
    }

    setRefModalData(username, offerId) {
        console.log(this.state);
        this.setState({
            refModalUsername: username,
            refModalOfferId: offerId,
        });
    }

    toggleOfferDetModal() {
        console.log(this.state);
        this.setState({
            offerDetailsModalOpen: !this.state.offerDetailsModalOpen,
        });
    }

    setOfferDetModalData(offerId) {
        console.log(this.state);
        this.setState({
            offerDetailsModalOfferId: offerId,
        });
    }

    getReferrals() {
        const card = this.props.card;

        // If no referrals for this card, scoop
        if (this.props.referralData.top_offer_id === null) {
            return (
                <div className="empty-referrals">
                    <a href={'/cards'} className="plain-black-text text-center">
                        We don't have any referrals for this card yet. Why not check out other cards?
                    </a>
                </div>
            );
        }

        // Top referral

        const topOfferSuggestion = this.props.referralSuggestions[CreditCardOffer.classInternalName].find(
            (sr) => sr.id === this.props.referralData.top_offer_id,
        );
        const topOfferRpSuggestion = this.props.referralSuggestions[RewardProgram.classInternalName].find(
            (sr) => sr.id === topOfferSuggestion[CreditCardOffer.f.rewardProgramId.key],
        );
        const topOfferRecord = this.props.referralData.offers.find(
            (or) => or.offer_id === this.props.referralData.top_offer_id,
        );
        const topReferral = topOfferRecord.referrals[0];
        const topReferralUser = this.props.referralSuggestions[User.classInternalName].find(
            (sr) => sr.id === topReferral.r[CreditCardReferral.f.userId.key],
        );

        const topReferralComponent = (
            <div>
                <h4 className="top-ref-header separated-text">Top Referral</h4>
                <div className="top-ref">
                    <CardReferralApplyView
                        offerSuggestion={topOfferSuggestion}
                        rpSuggestion={topOfferRpSuggestion}
                        referral={topReferral}
                        userSuggestion={topReferralUser}
                        card={card}
                    />
                </div>
            </div>
        );

        // Offers

        const offerComponents = [];
        this.props.referralData.offers.forEach((o) => {
            const offerSuggestion = this.props.referralSuggestions[CreditCardOffer.classInternalName].find(
                (sr) => sr.id === o.offer_id,
            );
            const offerRpSuggestion = this.props.referralSuggestions[RewardProgram.classInternalName].find(
                (sr) => sr.id === offerSuggestion[CreditCardOffer.f.rewardProgramId.key],
            );

            const referralList = o.referrals.map((r) => {
                const userSuggestion = this.props.referralSuggestions[User.classInternalName].find(
                    (sr) => sr.id === r.r[CreditCardReferral.f.userId.key],
                );
                const username = userSuggestion.display_name;

                return (
                    <div
                        className="ref-link"
                        key={r.id}
                        onClick={() => {
                            this.toggleRefModal();
                            this.setRefModalData(username, o.offer_id);
                        }}
                    >
                        {username}
                        <div>
                            <Modal
                                isOpen={
                                    this.state.refModalOpen &&
                                    this.state.refModalUsername === username &&
                                    this.state.refModalOfferId === o.offer_id
                                }
                                toggle={() => this.toggleRefModal()}
                                contentClassName="ref-link-modal"
                                centered={true}
                            >
                                <ModalHeader toggle={() => this.toggleRefModal()}>
                                    Referral from <b>{username}</b>
                                </ModalHeader>
                                <ModalBody>
                                    <CardReferralApplyView
                                        offerSuggestion={offerSuggestion}
                                        rpSuggestion={offerRpSuggestion}
                                        referral={r}
                                        userSuggestion={userSuggestion}
                                        card={card}
                                    />
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                );
            });

            const offerDetails = (
                <div className="offer-details">
                    <div className="offer-detail">
                        <span className="offer-detail-param">Annual Fee</span>
                        <span className="offer-detail-value">${offerSuggestion[CreditCardOffer.f.af.key]}</span>
                    </div>
                    <div className="offer-detail">
                        <span className="offer-detail-param">Minimum Spend</span>
                        <span className="offer-detail-value">${offerSuggestion[CreditCardOffer.f.msr.key]}</span>
                    </div>
                    <div className="offer-detail">
                        <span className="offer-detail-param">First Year Free</span>
                        <span className="offer-detail-value">
                            {offerSuggestion[CreditCardOffer.f.fyf.key] ? 'Yes' : 'No'}
                        </span>
                    </div>
                </div>
            );

            const offerDescriptionComponent = offerSuggestion[CreditCardOffer.f.description.key] ? (
                <div className="offer-description">{offerSuggestion[CreditCardOffer.f.description.key]}</div>
            ) : null;

            offerComponents.push(
                <div key={o.offer_id}>
                    <h4 className="text-center separated-text">
                        {offerSuggestion[CreditCardOffer.f.wb.key].toLocaleString()}{' '}
                        {this.props.referralSuggestions[RewardProgram.classInternalName][0].display_name}
                    </h4>
                    {offerDescriptionComponent}
                    <div className="offer-details-full">{offerDetails}</div>
                    <div>
                        <div className="offer-details-expand-button">
                            <Button
                                onClick={() => {
                                    this.toggleOfferDetModal();
                                    this.setOfferDetModalData(o.offer_id);
                                }}
                            >
                                Show Offer Details
                            </Button>
                        </div>
                        <Modal
                            isOpen={
                                this.state.offerDetailsModalOpen && this.state.offerDetailsModalOfferId === o.offer_id
                            }
                            toggle={() => this.toggleOfferDetModal()}
                            centered={true}
                        >
                            <ModalHeader toggle={() => this.toggleOfferDetModal()}>Offer Details</ModalHeader>
                            <ModalBody>{offerDetails}</ModalBody>
                        </Modal>
                    </div>
                    <h5 className="other-referrals-header">Referrals</h5>
                    <div className="other-referrals">{referralList}</div>
                </div>,
            );
        });

        return (
            <div>
                {topReferralComponent}
                {offerComponents}
            </div>
        );
    }

    render() {
        let bodyComponent;

        if (
            this.props.card === undefined ||
            this.props.referralSuggestions === undefined ||
            this.props.referralData === undefined
        ) {
            // If any of the network resources are not here, display a loading indicator
            bodyComponent = <LoadingContainer />;
        } else {
            bodyComponent = (
                <div>
                    {this.getCardHeader()}
                    <Container>{this.getReferrals()}</Container>
                </div>
            );
        }

        return bodyComponent;
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    return state.cardReferrals;
}

export default withRouter(
    connect(mapStateToProps, {
        fetchCard,
        fetchReferralList,
        cleanUpCardReferrals,
    })(CardReferralsView),
);
