// @flow

import React, { Component } from 'react';
import { FormGroup, Label, ButtonGroup, Button } from 'reactstrap';

type PropTypes = {
    id: string,
    value: boolean,
    displayName: string,
    onChange: (string, boolean) => void,
};

class BooleanControlledInputField extends Component<PropTypes, {}> {
    onChange(value: boolean) {
        this.props.onChange(this.props.id, value);
    }

    render() {
        return (
            <FormGroup>
                <Label for={this.props.id}>{this.props.displayName}</Label>
                <br />
                <ButtonGroup>
                    <Button active={this.props.value} onClick={() => this.onChange(true)}>
                        True
                    </Button>
                    <Button active={!this.props.value} onClick={() => this.onChange(false)}>
                        False
                    </Button>
                </ButtonGroup>
            </FormGroup>
        );
    }
}

export default BooleanControlledInputField;
