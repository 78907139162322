// @flow

import type { CardReferralsState, CardReferralsAction } from './types';

export function getDefaultCardReferralsState(): CardReferralsState {
    return {
        card: undefined,
        cardSuggestions: undefined,
        referralData: undefined,
        referralSuggestions: undefined,
    };
}

export function reduceCardReferrals(
    state: CardReferralsState = getDefaultCardReferralsState(),
    action: CardReferralsAction,
): CardReferralsState {
    switch (action.type) {
        case 'cardReferrals/receiveCard':
            return {
                ...state,
                card: action.card,
                cardSuggestions: action.cardSuggestions,
            };

        case 'cardReferrals/receiveList':
            return {
                ...state,
                referralData: action.referralData,
                referralSuggestions: action.referralSuggestions,
            };

        case 'cardReferrals/cleanUp':
            return getDefaultCardReferralsState();

        default:
            return state;
    }
}
