import React, { Component } from 'react';
import { Container } from 'reactstrap';

export default class LoadingContainer extends Component {
    // eslint-disable-next-line class-methods-use-this
    render() {
        return (
            <Container className="h-100 d-flex justify-content-center mt-5">
                <div className="spinner-border" style={{ width: '2.5rem', height: '2.5rem' }} role="status" />
            </Container>
        );
    }
}
