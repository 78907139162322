// @flow

import { setMessageAction } from '../messages/actions';
import { buildUrl, processFetchErrors } from '../requests';
import type { ReceiveRedditUri, RequestRedditUri } from './types';

function requestRedditUri(): RequestRedditUri {
    return {
        type: 'redditLogin/requestRedditUri',
    };
}

function receiveRedditUri(json): ReceiveRedditUri {
    return {
        type: 'redditLogin/receiveRedditUri',
        uri: json.url,
    };
}

export function fetchRedditUri() {
    return (dispatch) => {
        dispatch(requestRedditUri());

        processFetchErrors(
            fetch(buildUrl('users/reddit/profile-check/request'), {
                method: 'GET',
            }),
        )
            .then((json) => {
                dispatch(receiveRedditUri(json));
            })
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}
