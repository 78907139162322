// @flow

import type { AppState, AppAction } from './types';

function getDefaultAppState(): AppState {
    return {
        governor: undefined,
        initializationStatus: undefined,
    };
}

// eslint-disable-next-line import/prefer-default-export
export function reduceApp(state: AppState = getDefaultAppState(), action: AppAction): AppAction {
    switch (action.type) {
        case 'app/receiveGovernor':
            return {
                ...state,
                governor: action.governor,
            };

        case 'app/setInitializationStatus':
            return {
                ...state,
                initializationStatus: action.is,
            };

        default:
            return state;
    }
}
