// @flow

import type { UserProfileState, UserProfileAction } from './types';

export function getDefaultUserProfileState(): UserProfileState {
    return {
        user: undefined,
        cardReferrals: undefined,
        institutionReferrals: undefined,
        suggestions: undefined,
    };
}

export function reduceUserProfile(
    state: UserProfileState = getDefaultUserProfileState(),
    action: UserProfileAction,
): UserProfileState {
    switch (action.type) {
        case 'userProfile/receiveUser': {
            const newSuggestions = {
                ...state.suggestions,
                ...action.userSuggestions,
            };

            return {
                ...state,
                user: action.user,
                suggestions: newSuggestions,
            };
        }

        case 'userProfile/receiveCardReferrals': {
            const newSuggestions = {
                ...state.suggestions,
                ...action.cardRefSuggestions,
            };

            return {
                ...state,
                cardReferrals: action.cardReferrals,
                suggestions: newSuggestions,
            };
        }

        case 'userProfile/receiveInstitutionReferrals': {
            const newSuggestions = {
                ...state.suggestions,
                ...action.institutionRefSuggestions,
            };

            return {
                ...state,
                institutionReferrals: action.institutionReferrals,
                suggestions: newSuggestions,
            };
        }

        case 'userProfile/cleanUp':
            return getDefaultUserProfileState();

        default:
            return state;
    }
}
