// @flow

import type { AppInitializationStatus, ReceiveGovernor } from './types';

import { jsonGetRequest } from '../requests';
import { setMessageAction } from '../messages/actions';
import User from '../../entities/models/User';

function receiveGovernor(governor: any): ReceiveGovernor {
    return {
        type: 'app/receiveGovernor',
        governor: new User(governor),
    };
}

export function fetchGovernor() {
    return (dispatch) => {
        jsonGetRequest('users/me')
            .then((value) => dispatch(receiveGovernor(value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function setInitializationStatus(is: AppInitializationStatus) {
    return (dispatch) => {
        dispatch({
            type: 'app/setInitializationStatus',
            is,
        });
    };
}
