// @flow

import React, { Component } from 'react';
import { Button, Container } from 'reactstrap';
import { withRouter } from 'react-router';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';

import LoadingContainer from '../basicElements/LoadingContainer';

import ErrorWrapper from '../basicElements/messages/MessageWrapper';

import StyleClasses from '../../styles/StyleClasses';
import type { RedditAccountConfirmationStatus } from '../../store/redditAccountConfirmation/types';
import {
    requestEmailCheck,
    sendEmailCode,
    sendRedditConfirmation,
} from '../../store/redditAccountConfirmation/actions';
import ControlledInputField from '../basicElements/controls/ControlledInputField';
import { getRandomString } from '../utils';

//
// PROPS
//

type PropsType = {
    // STATE
    status: RedditAccountConfirmationStatus,
    uri: string,
    hasError: boolean,
    // ROUTER
    history: any,
    // INJECTED FUNCTIONS
    sendRedditConfirmation: (string, string) => void,
    requestEmailCheck: (string, string) => void,
    sendEmailCode: (string, string) => void,
};

//
// STATE
//

type StateType = {
    email: string,
    otp: string,
};

//
// CLASS
//

class RedditAccountConfirmationPage extends Component<PropsType, StateType> {
    ref: ?string;

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            otp: '',
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        // The check ref from the server
        this.ref = urlParams.get('state');
        // The code from reddit
        const code = urlParams.get('code');
        // Send it to server and see what comes back
        this.props.sendRedditConfirmation(this.ref, code);
    }

    componentDidUpdate(prevProps: PropsType) {
        // The verification went thru okay
        if (prevProps.status !== 'ok' && this.props.status === 'ok') {
            window.location.replace('/cards');
        }
    }

    submit() {
        if (this.props.status === 'email-check-needed') this.props.requestEmailCheck(this.ref, this.state.email);
        else if (this.props.status === 'otp-needed') this.props.sendEmailCode(this.ref, this.state.otp);
    }

    changeFormState(id, value) {
        this.setState({
            [id]: value,
        });
    }

    render() {
        if (this.props.status === 'pending' || this.props.status === undefined) {
            return (
                <div className={StyleClasses.centeredElement}>
                    <LoadingContainer />
                    <Button
                        className={StyleClasses.lotSpacedElement}
                        onClick={() => {
                            window.location.replace('/');
                        }}
                    >
                        Go to the Home Page
                    </Button>
                </div>
            );
        }

        const elements = [];
        if (this.props.status === 'email-check-needed' || this.props.status === 'otp-needed') {
            const emailInputField = (
                <ControlledInputField
                    id="email"
                    key="email"
                    value={this.state.email}
                    displayName="Email"
                    inputType="email"
                    disabled={this.props.status !== 'email-check-needed'}
                    onChange={(id, value) => this.changeFormState(id, value)}
                />
            );
            elements.push(emailInputField);

            if (this.props.status === 'otp-needed') {
                const otpInputField = (
                    <ControlledInputField
                        id="otp"
                        key="otp"
                        value={this.state.otp}
                        displayName="Verification Code"
                        inputType="text"
                        onChange={(id, value) => this.changeFormState(id, value)}
                    />
                );
                elements.push(otpInputField);

                elements.push(
                    <Container className="text-center mt-3">
                        <p>Thanks for Signing Up!</p>
                        <p>We have sent a verification code to the email address you provided.</p>
                        <p>
                            Your code may take up to 5 minutes to arrive, please do not try to sign up again before that
                            time.
                        </p>
                        <p>
                            We recommend you add churningcanada.com to your <b>“Approved Sender”</b> list within your
                            email client.
                        </p>
                        <p>
                            If you do not receive the verification code within a few minutes of signing up, please check
                            your Junk / Spam folder in case the email got delivered there instead of your inbox.
                        </p>
                    </Container>,
                );
            }

            elements.push(
                <Button key={getRandomString()} className={StyleClasses.lotSpacedElement} onClick={() => this.submit()}>
                    Send
                </Button>,
            );
        }

        let errorElement;
        if (this.props.hasError && this.props.status === 'email-check-needed')
            errorElement = (
                <span className="text-center mb-3 text-danger">
                    We are unable to process this email, please try another one
                </span>
            );
        else if (this.props.hasError && this.props.status === 'otp-needed')
            errorElement = (
                <span className="text-center mb-3 text-danger">
                    The code provided is not correct, please try another one
                </span>
            );

        return (
            <div>
                <ErrorWrapper>
                    <div className={StyleClasses.pageCenter} style={{ minWidth: '20vw' }}>
                        <h2 className={StyleClasses.spacedElement}>Welcome to Churning Canada</h2>
                        {errorElement}
                        {elements}
                    </div>
                </ErrorWrapper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.redditAccountConfirmation;
}

export default withRouter(
    connect(mapStateToProps, {
        sendRedditConfirmation,
        requestEmailCheck,
        sendEmailCode,
    })(RedditAccountConfirmationPage),
);
