// @flow

import { jsonGetRequest } from '../requests';
import { setMessageAction } from '../messages/actions';
import type { RefStatsCleanUp, RefStatsData, RefStatsReceiveData } from './types';
import CreditCardReferral from '../../entities/models/CreditCardReferral';

function receiveRefStats(response: { data: RefStatsData, suggestions: {} }): RefStatsReceiveData {
    return {
        type: 'refStats/receiveData',
        data: response.data,
        suggestions: response.suggestions,
    };
}

export function fetchRefStats() {
    return (dispatch) => {
        const listPromise = jsonGetRequest(CreditCardReferral.endpoints.stats());

        listPromise
            .then((value) => dispatch(receiveRefStats(value)))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function cleanUpRefStats(): RefStatsCleanUp {
    return {
        type: 'refStats/cleanUp',
    };
}
