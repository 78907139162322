import React, { Component } from 'react';
import type { QueryList, SortParams, Suggestions } from '../../entities/types';
import { getRandomString } from '../utils';
import LoadingContainer from '../basicElements/LoadingContainer';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Institution from '../../entities/models/Institution';
import '../../styles/cardList.css';

//
// IMAGES
//

import canadaLogo from '../../res/canada.png';
import usLogo from '../../res/united-states.png';
import worldLogo from '../../res/world.png';
import { cleanUpInstitutionList, fetchInstitutionList } from '../../store/institutionList/actions';
import { buildUrl } from '../../store/requests';

//
// PROPS
//

type PropsType = {
    // INJECTED FUNCTIONS
    fetchInstitutionList: (queryList: ?QueryList, sortParams: ?SortParams) => void,
    cleanUpInstitutionList: () => void,
    // STATE
    list: [Institution],
    count: number,
    suggestions: Suggestions,
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {};

//
// CLASS
//

class InstitutionListView extends Component<PropsType, StateType> {
    //
    // FETCH
    //

    fetch() {
        this.props.cleanUpInstitutionList();
        this.props.fetchInstitutionList(null, null);
    }

    //
    // COMPONENT LIFECYCLE
    //

    componentDidMount() {
        // Fetch the first page
        this.fetch(false);

        // SEO
        if (process.env.REACT_APP_SEO) {
            document.querySelectorAll('.seo-meta').forEach((e) => e.remove());
            document.head.innerHTML =
                document.head.innerHTML +
                '<title class="seo-meta">ChurningCanada Referrals - Best credit card offers in Canada</title>' +
                '<meta class="seo-meta" name="title" content="ChurningCanada Referrals - Best credit card offers in Canada">' +
                '<meta class="seo-meta" name="description" content="ChurningCanada Referrals provides the best credit card referral offers in Canada. We help you discover the best credit cards - maximum rewards, more savings.">' +
                '<meta class="seo-meta" property="og:type" content="website">' +
                '<meta class="seo-meta" property="og:url" content="https://referrals.churningcanada.com/">' +
                '<meta class="seo-meta" property="og:title" content="ChurningCanada Referrals - Best credit card offers in Canada">' +
                '<meta class="seo-meta" property="og:description" content="ChurningCanada Referrals provides the best credit card referral offers in Canada. We help you discover the best credit cards - maximum rewards, more savings.">' +
                '<meta class="seo-meta" property="og:image" content="">' +
                '<meta class="seo-meta" property="twitter:card" content="summary_large_image">' +
                '<meta class="seo-meta" property="twitter:url" content="https://referrals.churningcanada.com/">' +
                '<meta class="seo-meta" property="twitter:title" content="ChurningCanada Referrals - Best credit card offers in Canada">' +
                '<meta class="seo-meta" property="twitter:description" content="ChurningCanada Referrals provides the best credit card referral offers in Canada. We help you discover the best credit cards - maximum rewards, more savings.">' +
                '<meta class="seo-meta" property="twitter:image" content="">';
        }
    }

    componentWillUnmount() {
        // Clean up the state
        this.props.cleanUpInstitutionList();
    }

    //
    // RENDER
    //

    render() {
        if (this.props.suggestions === undefined || this.props.list === undefined || this.props.count === undefined) {
            // If any of the network resources are not here, display a loading indicator
            return <LoadingContainer />;
        }

        const institutions = this.props.list.map((c) => {
            const logoEndpoint = buildUrl(Institution.endpoints.file(c.id, Institution.fileFields.logo.fileName));

            let geographyLogo;
            switch (c.r[Institution.f.geography.key]) {
                case 1:
                    geographyLogo = canadaLogo;
                    break;
                case 4:
                    geographyLogo = usLogo;
                    break;
                case 5:
                    geographyLogo = worldLogo;
                    break;
                default:
                    break;
            }

            return (
                <Col
                    xs={6}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                    key={getRandomString()}
                    onClick={() => this.props.history.push(`/institutions/${c.id}/referrals`)}
                >
                    <div className="card-container">
                        <img src={logoEndpoint} alt="logo" className="card-logo" />
                        <div className="card-label">
                            <img src={geographyLogo} alt="geography-logo" className="card-geo-logo" />
                            <span className="mb-0">{c.r[Institution.f.name.key]}</span>
                        </div>
                    </div>
                </Col>
            );
        });

        const topBarComponent = (
            <div>
                <div className="search-bar pt-2 pb-2">
                    <div className="search-bar-ref-selectors">
                        <div className="search-bar-ref-selector">
                            <input
                                type="radio"
                                className="search-bar-ref-selector"
                                name="ref-type"
                                id="ref-type-cards"
                                value="Show Credit Card Referrals"
                                onChange={() => this.props.history.push('/cards')}
                            />
                            <label htmlFor="ref-type-cards">
                                <span role="img" aria-label="Cards">
                                    💳
                                </span>{' '}
                                Cards
                            </label>
                        </div>
                        <div className="search-bar-ref-selector">
                            <input
                                type="radio"
                                name="ref-type"
                                id="ref-type-others"
                                value="Show Other Referrals"
                                defaultChecked
                            />
                            <label htmlFor="ref-type-others">
                                <span role="img" aria-label="Other Referrals">
                                    💸
                                </span>{' '}
                                Other Referrals
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );

        const header =
            institutions.length > 0 ? (
                <h3 className="text-center mt-5 separated-text">Other Referrals</h3>
            ) : (
                <h6 className="text-center mt-5">There are no institutions to display</h6>
            );

        // Create the grid
        return (
            <div>
                {topBarComponent}
                <Container>
                    {header}
                    <Row>{institutions}</Row>
                </Container>
            </div>
        );
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    return state.institutionList;
}

export default withRouter(
    connect(mapStateToProps, { fetchInstitutionList, cleanUpInstitutionList })(InstitutionListView),
);
