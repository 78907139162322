// @flow

import { jsonGetRequest } from '../requests';
import { setMessageAction } from '../messages/actions';
import type { QueryList, SortParams } from '../../entities/types';
import CreditCard from '../../entities/models/CreditCard';
import type { CardListCleanUp, CardListReceive } from './types';

function receiveCardList(response: { list: Array<Object>, suggestions: {} }, count: any): CardListReceive {
    return {
        type: 'cardList/receive',
        list: response.list.map((record) => new CreditCard(record)),
        count: count.count,
        suggestions: response.suggestions,
    };
}

function processCardQueryList(queryList: ?QueryList): string {
    if (!queryList) return null;

    const query = {};

    Object.values(queryList).forEach((queryRecord) => {
        // Ignore meaningless search queries
        const isEmpty = queryRecord.value.length === 0;
        if (isEmpty) {
            return;
        }

        const rName = queryRecord.field.key;

        switch (queryRecord.field.dataType.primitive) {
            case 'enum':
                // Make it a number from the HTML string input or the object string key
                query[rName] = {
                    value: queryRecord.value.map((v) => parseInt(v, 10)),
                };
                break;
            case 'bool':
                query[rName] = {
                    value: queryRecord.value.map((v) => Boolean(parseInt(v, 10))),
                };
                break;
            default:
                query[rName] = {
                    value: queryRecord.value,
                };
        }

        query[rName].mode = queryRecord.mode;
    });

    return JSON.stringify(query);
}

export function fetchCardList(queryList: ?QueryList, sortParams: ?SortParams) {
    return (dispatch) => {
        const listPromise = jsonGetRequest(
            CreditCard.endpoints.list(processCardQueryList(queryList), sortParams, null),
        );
        const countPromise = jsonGetRequest(CreditCard.endpoints.count(queryList));

        Promise.all([listPromise, countPromise])
            .then((values) => dispatch(receiveCardList(values[0], values[1])))
            .catch((error) => dispatch(setMessageAction(error.toString(), 'error')));
    };
}

export function cleanUpCardList(): CardListCleanUp {
    return {
        type: 'cardList/cleanUp',
    };
}
