// @flow

import React, { Component } from 'react';
import { FormGroup, CustomInput, Label } from 'reactstrap';

type PropsType = {
    id: string,
    displayName: string,
    onFileUpdated: (File) => void,
};

class FileInputField extends Component<PropsType> {
    constructor(props) {
        super(props);
        this.state = {
            label: 'Please select file...',
        };
        this.ref = React.createRef();
    }

    onChange() {
        const currentFile = this.ref.current.files[0];
        const onFileUpdated = () => this.props.onFileUpdated(this.props.id, currentFile);
        // Send file to container after setting label
        this.setState({ label: currentFile.name }, onFileUpdated);
    }

    render() {
        return (
            <FormGroup>
                <Label for={this.props.id}>{this.props.displayName}</Label>
                <CustomInput
                    type="file"
                    id={this.props.id}
                    label={this.state.label}
                    innerRef={this.ref}
                    onChange={() => this.onChange()}
                />
            </FormGroup>
        );
    }
}

export default FileInputField;
