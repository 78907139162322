// @flow

import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import type { MessageType } from '../../../store/messages/types';

type PropTypes = {
    message: string,
    messageType: MessageType,
};

class MessageAlert extends Component<PropTypes, {}> {
    render() {
        let color;
        switch (this.props.messageType) {
            case 'error':
                color = 'danger';
                break;
            case 'success':
                color = 'success';
                break;
            default:
                color = 'info';
        }
        return (
            <Alert color={color} className={`m-0 element-with-sharp-corners`}>
                {this.props.message}
            </Alert>
        );
    }
}

export default MessageAlert;
