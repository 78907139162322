// @flow

import { Button } from 'reactstrap';
import React from 'react';
import type { ValueToDisplayNameMap } from '../entities/types';
import StyleClasses from '../styles/StyleClasses';

export function getRandomString(len = 5) {
    /*
     * Get a random string, mainly for React element keys in arrays.
     */
    const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < len; i++) {
        const randomPos = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPos, randomPos + 1);
    }
    return randomString;
}

export function getActionButton(label, action) {
    return (
        <Button key={label} onClick={action} className={StyleClasses.littleSpacedElement}>
            {label}
        </Button>
    );
}

export const booleanVtDM: ValueToDisplayNameMap = {
    1: 'Yes',
    0: 'No',
};

export const zip = (a, b) => a.map((x, i) => [x, b[i]]);

export function addScript(attributes, text) {
    let s = document.createElement('script');
    for (const attr in attributes) {
        s.setAttribute(attr, attributes[attr] ? attributes[attr] : null);
    }
    s.innerHTML = text;
    document.head.appendChild(s);
}

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}
