// @flow

import React, { Children } from 'react';
import { connect } from 'react-redux';

import MessageAlert from './MessageAlert';

import type { MessageType } from '../../../store/messages/types';

type PropsType = {
    children: Children,
    message: ?string,
    messageType: MessageType,
};

const MessageWrapper = ({ children, message, messageType }: PropsType) => (
    <div>
        {message && <MessageAlert message={message} messageType={messageType} />}
        {children}
    </div>
);

function mapStateToProps(state) {
    return { message: state.messages.message, messageType: state.messages.messageType };
}

export default connect(mapStateToProps)(MessageWrapper);
