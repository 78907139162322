// @flow

import type { InstitutionListState, InstitutionListAction } from './types';

export function getDefaultInstitutionListState(): InstitutionListState {
    return {
        list: undefined,
        count: undefined,
        suggestions: undefined,
    };
}

export function reduceInstitutionList(
    state: InstitutionListState = getDefaultInstitutionListState(),
    action: InstitutionListAction,
): InstitutionListState {
    switch (action.type) {
        case 'institutionReferrals/receive':
            return {
                ...state,
                list: action.list,
                count: action.count,
                suggestions: action.suggestions,
            };

        case 'institutionReferrals/cleanUp':
            return getDefaultInstitutionListState();

        default:
            return state;
    }
}
