// @flow

import type { Endpoints, EntityFieldCollection, SortParams } from '../types';
import type { StoredEntity } from '../abstract/StoredEntity';
import { createdAtField, getCreatedAtSort, idField } from '../abstract/StoredEntity';
import RewardProgram from './RewardProgram';
import Institution from './Institution';
import { constructEndpoints } from '../helpers';
import { Geography } from '../types';

class InstitutionOffer implements StoredEntity {
    static classInternalName = 'institution_offer';

    static f: EntityFieldCollection = {
        id: idField,
        createdAt: createdAtField,
        institutionId: {
            displayName: 'Institution',
            key: 'institution_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: Institution,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        rewardProgramId: {
            displayName: 'Reward Program',
            key: 'reward_program_id',
            dataType: {
                primitive: 'ref',
                isArray: false,
                extraData: {
                    model: RewardProgram,
                },
            },
            supportsQuery: true,
            supportsSort: false,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        offerType: {
            displayName: 'Type',
            key: 'offer_type',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: {
                    1: 'Referral',
                    2: 'Affiliate',
                },
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        geography: {
            displayName: 'Geography',
            key: 'geography',
            dataType: {
                primitive: 'enum',
                isArray: false,
                extraData: Geography,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        description: {
            displayName: 'Description',
            key: 'description',
            dataType: {
                primitive: 'str',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        netValue: {
            displayName: 'Net Value',
            key: 'net_value',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        wb: {
            displayName: 'WB',
            key: 'wb',
            dataType: {
                primitive: 'num',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
        enabled: {
            displayName: 'Enabled',
            key: 'enabled',
            dataType: {
                primitive: 'bool',
                isArray: false,
                extraData: null,
            },
            supportsQuery: true,
            supportsSort: true,
            needToCreate: true,
            canEdit: true,
            isOptional: false,
            displayOnMain: true,
        },
    };

    static defaultSort: SortParams = getCreatedAtSort();

    get id() {
        return this.r[InstitutionOffer.f.id.key];
    }

    get displayName() {
        return this.r[InstitutionOffer.f.name.key];
    }

    static endpoints: Endpoints = constructEndpoints('cc-offers');

    constructor(record: any) {
        this.r = record;
    }
}

export default InstitutionOffer;
