// @flow

import { combineReducers } from 'redux';
import { reduce as messages } from './messages/reducers';
import { reduceRedditAccountConfirmation as redditAccountConfirmation } from './redditAccountConfirmation/reducers';
import { reduceApp as app } from './app/reducers';
import { reduceRedditLogin as redditLogin } from './redditLogin/reducers';
import { reduceCardList as cardList } from './cardList/reducers';
import { reduceCardReferrals as cardReferrals } from './cardReferrals/reducers';
import { reduceUserProfile as userProfile } from './userProfile/reducers';
import { reduceRefStats as refStats } from './refStats/reducers';
import { reduceInstitutionList as institutionList } from './institutionList/reducers';
import { reduceInstitutionReferrals as institutionReferrals } from './institutionReferrals/reducers';

export default combineReducers({
    redditLogin,
    redditAccountConfirmation,
    cardList,
    cardReferrals,
    refStats,
    userProfile,
    messages,
    app,
    institutionList,
    institutionReferrals,
});
