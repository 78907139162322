import React from 'react';
import ReactDOM from 'react-dom';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import * as serviceWorker from './serviceWorker';
import App from './App';
import reducers from './store/reducers';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.css';
import { addScript } from './ui/utils';

const store = createStore(reducers, applyMiddleware(logger, thunk));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

if (process.env.REACT_APP_CLICKY_ID) {
    addScript({ async: true, src: '//static.getclicky.com/js' });
    addScript(
        {},
        `var clicky_site_ids = clicky_site_ids || []; clicky_site_ids.push(${process.env.REACT_APP_CLICKY_ID});`,
    );
}

if (process.env.REACT_APP_HEAP_ID) {
    addScript(
        {},
        `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load("${process.env.REACT_APP_HEAP_ID}");`,
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
