// @flow

import type { StoredEntityField } from '../entities/types';

export function getDefaultValueForField(field: StoredEntityField) {
    switch (field.dataType.primitive) {
        case 'str':
        case 'simple-entity':
        case 'num':
        case 'ts':
            return '';
        case 'ref':
            return [];
        case 'enum':
        case 'bool':
            return undefined;
        default:
            return null;
    }
}
