// @flow

import React, { Component } from 'react';
import '../../styles/faq.css';
import CreditCardOffer from '../../entities/models/CreditCardOffer';
import RewardProgram from '../../entities/models/RewardProgram';
import { Button } from 'reactstrap';
import CreditCardReferral from '../../entities/models/CreditCardReferral';
import type { Suggestion } from '../../entities/types';
import CreditCard from '../../entities/models/CreditCard';
import { buildUrl } from '../../store/requests';
import ReCAPTCHA from 'react-google-recaptcha';

//
// PROPS
//

type PropsType = {
    // DATA
    offerSuggestion: Suggestion,
    rpSuggestion: Suggestion,
    referral: CreditCardReferral,
    userSuggestion: Suggestion,
    card: CreditCard,
};

//
// STATE
//

type StateType = {
    applyEnabled: boolean,
};

//
// CLASS
//

class CardReferralApplyView extends Component<PropsType, StateType> {
    constructor() {
        super();
        this.state = {
            applyEnabled: false,
        };
    }

    render() {
        const p = this.props;

        return (
            <div className="ref-card">
                <div className="ref-card-username">
                    <span>
                        <b>{p.userSuggestion.display_name}</b> would like to refer you to the{' '}
                        <b>{p.card.r[CreditCard.f.name.key]}</b> card
                    </span>
                </div>
                <div className="ref-card-offer">
                    <div className="ref-card-offer-desc">
                        Spend ${p.offerSuggestion[CreditCardOffer.f.msr.key]} within the first 3 months to receive
                    </div>
                    <div className="ref-card-offer-reward">
                        {p.offerSuggestion[CreditCardOffer.f.wb.key].toLocaleString()} {p.rpSuggestion.display_name}{' '}
                        {p.rpSuggestion[RewardProgram.f.type.key] === 2 ? 'dollars' : 'points'}
                    </div>
                    <div className="ref-card-offer-reward-desc">
                        {p.offerSuggestion[CreditCardOffer.f.description.key]}
                    </div>
                </div>
                <form
                    id={`form-${p.referral.id}`}
                    method="POST"
                    className="ref-card-apply plain-white-text ref-card-apply-child"
                    action={buildUrl(CreditCardReferral.endpoints.link(p.referral.id))}
                >
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={() => this.setState({ applyEnabled: true })}
                        theme="dark"
                    />
                    <Button color="info" size="lg" className="mt-3" disabled={!this.state.applyEnabled}>
                        Apply
                    </Button>
                    <span className="disclaimer mt-3">
                        By clicking "Apply", I confirm that I have a personal or professional relationship with the
                        person who is referring me
                    </span>
                </form>
            </div>
        );
    }
}

//
// ROUTER
//

export default CardReferralApplyView;
