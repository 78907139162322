// @flow

import type { InstitutionReferralsState, InstitutionReferralsAction } from './types';

export function getDefaultInstitutionReferralsState(): InstitutionReferralsState {
    return {
        institution: undefined,
        institutionSuggestions: undefined,
        referralData: undefined,
        referralSuggestions: undefined,
        lastLink: undefined,
        lastLinkReferralId: undefined,
    };
}

export function reduceInstitutionReferrals(
    state: InstitutionReferralsState = getDefaultInstitutionReferralsState(),
    action: InstitutionReferralsAction,
): InstitutionReferralsState {
    switch (action.type) {
        case 'institutionReferrals/receiveInstitution':
            return {
                ...state,
                institution: action.institution,
                institutionSuggestions: action.institutionSuggestions,
            };

        case 'institutionReferrals/receiveList':
            return {
                ...state,
                referralData: action.referralData,
                referralSuggestions: action.referralSuggestions,
            };

        case 'institutionReferrals/receiveLink':
            return {
                ...state,
                lastLink: action.link,
                lastLinkReferralId: action.referralId,
            };

        case 'institutionReferrals/cleanUp':
            return getDefaultInstitutionReferralsState();

        default:
            return state;
    }
}
