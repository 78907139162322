// @flow

import React, { Component } from 'react';
import '../../styles/faq.css';
import { Button } from 'reactstrap';
import InstitutionReferral from '../../entities/models/InstitutionReferral';
import type { Suggestion } from '../../entities/types';
import Institution from '../../entities/models/Institution';
import ReCAPTCHA from 'react-google-recaptcha';
import InstitutionOffer from '../../entities/models/InstitutionOffer';
import { isValidHttpUrl } from '../utils';
import { withRouter } from 'react-router';

//
// PROPS
//

type PropsType = {
    // DATA
    offerSuggestion: Suggestion,
    rpSuggestion: Suggestion,
    referral: InstitutionReferral,
    userSuggestion: Suggestion,
    institution: Institution,
    onSubmit: (e: Event) => void,
    link: string,
    history: any,
};

//
// STATE
//

type StateType = {
    captchaToken: string,
};

//
// CLASS
//

class InstitutionReferralApplyView extends Component<PropsType, StateType> {
    constructor() {
        super();
        this.state = {
            captchaToken: undefined,
        };
    }

    copyLink() {
        navigator.clipboard.writeText(this.props.link);
    }

    render() {
        const p = this.props;

        let codeElement;
        if (this.props.link) {
            if (isValidHttpUrl(this.props.link)) {
                window.location.href = this.props.link;
            } else {
                codeElement = (
                    <div className="ref-card-copy-link-menu">
                        <span className="ref-card-copy-link-label">
                            <span className="ref-card-copy-link-username">{p.userSuggestion.display_name}'s</span>{' '}
                            Referral Code:
                        </span>
                        <span className="ref-card-copy-link-content">{this.props.link}</span>
                        <Button color="info" size="lg" onClick={() => this.copyLink()}>
                            Copy
                        </Button>
                    </div>
                );
            }
        } else {
            codeElement = (
                <div className="ref-card-apply-child">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={(t) => this.setState({ captchaToken: t })}
                        theme="dark"
                    />
                    <Button
                        color="info"
                        size="lg"
                        className="mt-3"
                        disabled={!this.state.captchaToken}
                        onClick={(e) => p.onSubmit(e, this.state.captchaToken)}
                    >
                        Apply
                    </Button>
                    <span className="disclaimer mt-3">
                        By clicking "Apply", I confirm that I have a personal or professional relationship with the
                        person who is referring me
                    </span>
                </div>
            );
        }

        return (
            <div className="ref-card">
                <div className="ref-card-username">
                    <span>
                        <b>{p.userSuggestion.display_name}</b> would like to refer you to{' '}
                        <b>{p.institution.r[Institution.f.name.key]}</b>
                    </span>
                </div>
                <div className="ref-card-offer">
                    <div className="ref-card-offer-desc">
                        Upon signing up and fulfilling the eligibility requirements, receive
                    </div>
                    <div className="ref-card-offer-reward">
                        {p.offerSuggestion[InstitutionOffer.f.wb.key].toLocaleString()} {p.rpSuggestion.display_name}
                    </div>
                    <div className="ref-card-offer-reward-desc">
                        {p.offerSuggestion[InstitutionOffer.f.description.key]}
                    </div>
                </div>
                <form id={`form-${p.referral.id}`} className="ref-card-apply plain-white-text" method="POST">
                    {codeElement}
                </form>
            </div>
        );
    }
}

//
// ROUTER
//

export default withRouter(InstitutionReferralApplyView);
