// @flow

import React, { Component } from 'react';
import { Container } from 'reactstrap';
import '../../styles/faq.css';
import { withRouter } from 'react-router';
import { FaqDataGeneral, FaqDataRanking, FaqDataSubmission } from './FaqData';
import FaqEntry from './FaqEntry';

//
// PROPS
//

type PropsType = {
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {};

//
// CLASS
//

class FaqView extends Component<PropsType, StateType> {
    render() {
        return (
            <Container>
                <h2 className="my-4">FAQ</h2>
                <div>
                    <h2>General</h2>
                    {FaqDataGeneral.mainEntity.map((qa, idx) => (
                        <FaqEntry qa={qa} key={idx} />
                    ))}
                </div>
                <div>
                    <h2>Submission</h2>
                    {FaqDataSubmission.mainEntity.map((qa, idx) => (
                        <FaqEntry qa={qa} key={idx} />
                    ))}
                </div>
                <div>
                    <h2>Ranking</h2>
                    {FaqDataRanking.mainEntity.map((qa, idx) => (
                        <FaqEntry qa={qa} key={idx} />
                    ))}
                </div>
            </Container>
        );
    }
}

//
// ROUTER
//

export default withRouter(FaqView);
