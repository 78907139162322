import React, { Component } from 'react';
import type { QueryList, SortParams, Suggestions } from '../../entities/types';
import Institution from '../../entities/models/Institution';
import LoadingContainer from '../basicElements/LoadingContainer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { buildUrl } from '../../store/requests';
import InstitutionReferral from '../../entities/models/InstitutionReferral';
import RewardProgram from '../../entities/models/RewardProgram';
import InstitutionOffer from '../../entities/models/InstitutionOffer';
import User from '../../entities/models/User';
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import '../../styles/cardReferrals.css';
import type { ReferralData } from '../../store/cardReferrals/types';
import InstitutionReferralApplyView from './InstitutionReferralApplyView';
import {
    cleanUpInstitutionReferrals,
    fetchInstitution,
    fetchReferralList,
    sendLinkForm,
} from '../../store/institutionReferrals/actions';

//
// IMAGES
//

import worldLogo from '../../res/world.png';
import canadaLogo from '../../res/canada.png';
import usLogo from '../../res/united-states.png';

//
// PROPS
//

type PropsType = {
    // PROPS
    governor: ?User,
    // INJECTED FUNCTIONS
    fetchInstitution: (string) => void,
    fetchReferralList: (queryList: ?QueryList, sortParams: ?SortParams) => void,
    cleanUpInstitutionReferrals: () => void,
    sendLinkForm: (referralId: string, body: Object) => void,
    // STATE
    institution: Institution,
    referralData: ReferralData,
    referralSuggestions: Suggestions,
    lastLink: string,
    lastLinkReferralId: string,
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {
    refModalOpen: boolean,
    refModalOfferId: string,
    refModalUsername: string,
    offerDetailsModalOpen: boolean,
    offerDetailsModalOfferId: string,
};

//
// CLASS
//

class InstitutionReferralsView extends Component<PropsType, StateType> {
    institutionId: string;

    //
    // INIT
    //

    constructor() {
        super();
        this.state = {
            refModalOpen: false,
            refModalOfferId: undefined,
            refModalUsername: undefined,
            offerDetailsModalOpen: false,
            offerDetailsModalOfferId: undefined,
        };
    }

    //
    // FETCH
    //

    fetch() {
        this.props.fetchInstitution(this.institutionId);
    }

    //
    // COMPONENT LIFECYCLE
    //

    componentDidMount() {
        this.institutionId = this.props.match.params.id;
        this.fetch();
    }

    componentDidUpdate(prevProps: PropsType) {
        if (prevProps.institution === undefined && this.props.institution) {
            this.props.fetchReferralList(this.props.institution);
            const institutionName = this.props.institution.r[Institution.f.name.key];

            // SEO
            if (process.env.REACT_APP_SEO) {
                document.querySelectorAll('.seo-meta').forEach((e) => e.remove());
                document.head.innerHTML =
                    document.head.innerHTML +
                    `<title class="seo-meta">ChurningCanada Referrals - ${institutionName}</title>` +
                    `<meta class="seo-meta" name="title" content="ChurningCanada Referrals - ${institutionName}">` +
                    `<meta class="seo-meta" name="description" content="Best referral offer for ${institutionName} in Canada - earn more welcome bonus and save on annual fees. ">` +
                    '<meta class="seo-meta" property="og:type" content="website">' +
                    `<meta class="seo-meta" property="og:url" content="${document.location.href}">` +
                    `<meta class="seo-meta" property="og:title" content="ChurningCanada Referrals - ${institutionName}">` +
                    `<meta class="seo-meta" property="og:description" content="Best referral offer for ${institutionName} in Canada - earn more welcome bonus and save on annual fees. ">` +
                    '<meta class="seo-meta" property="og:image" content="">' +
                    '<meta class="seo-meta" property="twitter:card" content="summary_large_image">' +
                    `<meta class="seo-meta" property="twitter:url" content="${document.location.href}">` +
                    `<meta class="seo-meta" property="twitter:title" content="ChurningCanada Referrals - ${institutionName}">` +
                    `<meta class="seo-meta" property="twitter:description" content="Best referral offer for ${institutionName} in Canada - earn more welcome bonus and save on annual fees. ">` +
                    '<meta class="seo-meta" property="twitter:image" content="">';
            }
        }
    }

    componentWillUnmount() {
        // Clean up the state
        this.props.cleanUpInstitutionReferrals();
    }

    //
    // RENDER
    //

    getInstitutionHeader() {
        const institution = this.props.institution;

        // Build the image endpoints
        const institutionId = institution.r[Institution.f.id.key];
        const institutionLogoEndpoint = buildUrl(
            Institution.endpoints.file(institutionId, Institution.fileFields.logo.fileName),
        );

        // Geography logo
        let geographyLogo;
        let myInstitution = this.props.referralSuggestions[Institution.classInternalName].find(
            (e) => e.id === institutionId,
        );
        switch (myInstitution[Institution.f.geography.key]) {
            case 1:
                geographyLogo = canadaLogo;
                break;
            case 4:
                geographyLogo = usLogo;
                break;
            case 5:
                geographyLogo = worldLogo;
                break;
            default:
                break;
        }

        return (
            <div id="card-header">
                <img id="card-logo" src={institutionLogoEndpoint} alt="card-logo" />
                <div id="card-name">
                    <h2 className="mb-0">{institution.r[Institution.f.name.key]}</h2>
                </div>
                <div id="card-data">
                    <div id="card-geography">
                        <b>Geography</b>
                        <img src={geographyLogo} alt="geography-logo" />
                    </div>
                </div>
            </div>
        );
    }

    toggleRefModal() {
        console.log(this.state);
        this.setState({
            refModalOpen: !this.state.refModalOpen,
        });
    }

    setRefModalData(username, offerId) {
        console.log(this.state);
        this.setState({
            refModalUsername: username,
            refModalOfferId: offerId,
        });
    }

    getReferrals() {
        const institution = this.props.institution;

        // If no referrals for this institution, scoop
        if (this.props.referralData.top_offer_id === null) {
            return (
                <div className="empty-referrals">
                    <a href={'/institutions'} className="plain-black-text text-center">
                        We don't have any referrals for this institution yet. Why not check out other institutions?
                    </a>
                </div>
            );
        }

        // Top referral

        const topOfferSuggestion = this.props.referralSuggestions[InstitutionOffer.classInternalName].find(
            (sr) => sr.id === this.props.referralData.top_offer_id,
        );
        const topOfferRpSuggestion = this.props.referralSuggestions[RewardProgram.classInternalName].find(
            (sr) => sr.id === topOfferSuggestion[InstitutionOffer.f.rewardProgramId.key],
        );
        const topOfferRecord = this.props.referralData.offers.find(
            (or) => or.offer_id === this.props.referralData.top_offer_id,
        );
        const topReferral = topOfferRecord.referrals[0];
        const topReferralUser = this.props.referralSuggestions[User.classInternalName].find(
            (sr) => sr.id === topReferral.r[InstitutionReferral.f.userId.key],
        );

        const topReferralComponent = (
            <div>
                <h4 className="top-ref-header separated-text">Top Referral</h4>
                <div className="top-ref">
                    <InstitutionReferralApplyView
                        offerSuggestion={topOfferSuggestion}
                        rpSuggestion={topOfferRpSuggestion}
                        referral={topReferral}
                        userSuggestion={topReferralUser}
                        institution={institution}
                        onSubmit={(e, t) => this.submitForm(e, topReferral, t)}
                        link={topReferral.id === this.props.lastLinkReferralId ? this.props.lastLink : undefined}
                    />
                </div>
            </div>
        );

        // Offers

        const offerComponents = [];
        this.props.referralData.offers.forEach((o) => {
            const offerSuggestion = this.props.referralSuggestions[InstitutionOffer.classInternalName].find(
                (sr) => sr.id === o.offer_id,
            );
            const offerRpSuggestion = this.props.referralSuggestions[RewardProgram.classInternalName].find(
                (sr) => sr.id === offerSuggestion[InstitutionOffer.f.rewardProgramId.key],
            );

            const referralList = o.referrals.map((r) => {
                const userSuggestion = this.props.referralSuggestions[User.classInternalName].find(
                    (sr) => sr.id === r.r[InstitutionReferral.f.userId.key],
                );
                const username = userSuggestion.display_name;

                return (
                    <div
                        className="ref-link"
                        key={r.id}
                        onClick={() => {
                            this.toggleRefModal();
                            this.setRefModalData(username, o.offer_id);
                        }}
                    >
                        {username}
                        <div>
                            <Modal
                                isOpen={
                                    this.state.refModalOpen &&
                                    this.state.refModalUsername === username &&
                                    this.state.refModalOfferId === o.offer_id
                                }
                                toggle={() => this.toggleRefModal()}
                                contentClassName="ref-link-modal"
                                centered={true}
                            >
                                <ModalHeader toggle={() => this.toggleRefModal()}>
                                    Referral from <b>{username}</b>
                                </ModalHeader>
                                <ModalBody>
                                    <InstitutionReferralApplyView
                                        offerSuggestion={offerSuggestion}
                                        rpSuggestion={offerRpSuggestion}
                                        referral={r}
                                        userSuggestion={userSuggestion}
                                        institution={institution}
                                        onSubmit={(e, t) => this.submitForm(e, r, t)}
                                        link={r.id === this.props.lastLinkReferralId ? this.props.lastLink : undefined}
                                    />
                                </ModalBody>
                            </Modal>
                        </div>
                    </div>
                );
            });

            const offerDescriptionComponent = offerSuggestion[InstitutionOffer.f.description.key] ? (
                <div className="offer-description">{offerSuggestion[InstitutionOffer.f.description.key]}</div>
            ) : null;

            offerComponents.push(
                <div key={o.offer_id}>
                    <h4 className="text-center separated-text">
                        {offerSuggestion[InstitutionOffer.f.wb.key].toLocaleString()}{' '}
                        {this.props.referralSuggestions[RewardProgram.classInternalName][0].display_name}
                    </h4>
                    {offerDescriptionComponent}
                    <div className="other-referrals">{referralList}</div>
                </div>,
            );
        });

        return (
            <div>
                {topReferralComponent}
                {offerComponents}
            </div>
        );
    }

    submitForm(event: Event, referral: InstitutionReferral, captchaToken: string) {
        event.preventDefault();
        this.props.sendLinkForm(referral.id, { 'g-recaptcha-response': captchaToken });
    }

    render() {
        let bodyComponent;

        if (
            this.props.institution === undefined ||
            this.props.referralSuggestions === undefined ||
            this.props.referralData === undefined
        ) {
            // If any of the network resources are not here, display a loading indicator
            bodyComponent = <LoadingContainer />;
        } else {
            bodyComponent = (
                <div>
                    {this.getInstitutionHeader()}
                    <Container>{this.getReferrals()}</Container>
                </div>
            );
        }

        return bodyComponent;
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    return state.institutionReferrals;
}

export default withRouter(
    connect(mapStateToProps, {
        fetchInstitution,
        fetchReferralList,
        cleanUpInstitutionReferrals,
        sendLinkForm,
    })(InstitutionReferralsView),
);
