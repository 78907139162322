// @flow
import type { MessagesAction, MessagesClear, MessageType } from './types';

type PromiseActionType = Promise<MessagesAction>;
type ThunkActionType = (dispatch: DispatchType) => any; // eslint-disable-line no-use-before-define
type DispatchType = (action: MessagesAction | ThunkActionType | PromiseActionType | Array<MessagesAction>) => any;

export const setMessageAction = (message: string, messageType: MessageType): ThunkActionType => async (
    dispatch: DispatchType,
) => {
    const timer = setTimeout(() => {
        dispatch(clearMessageAction());
    }, 5000);
    dispatch({
        type: 'messages/set',
        message,
        messageType,
        timer,
    });
};

export const clearMessageAction = (): MessagesClear => ({ type: 'messages/clear' });
