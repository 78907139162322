export const FaqDataGeneral = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
        {
            '@type': 'Question',
            name: 'What is referrals.churningcanada.com ?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'referrals.churningcanada.com is a community project under the ChurningCanada umbrella. It is aimed to provide a better user experience and additional functionality when compared to outdated reddit referral threads.',
            },
        },
        {
            '@type': 'Question',
            name: 'Is referrals.churningcanada.com replacing the monthly referral thread ?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'Yes, the idea of referrals.churningcanada.com is to eventually replace the monthly referral threads of /r/churningcanada and provide a better experience',
            },
        },
        {
            '@type': 'Question',
            name: 'I am not a reddit user - can I use this portal ?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'Yes, the portal is open to anyone. Everyone (regardless of reddit membership) can use the portal to access the best available offers in the Canadian market. However, only /r/churningcanada users will be allowed to submit their referrals at this time. ',
            },
        },
        {
            '@type': 'Question',
            name: 'What is /r/churningCanada ?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    '/r/ChurningCanada is a reddit community which discusses about welcome bonuses, referrals points, and everything related to Canadian banks and credit cards.',
            },
        },
        {
            '@type': 'Question',
            name: 'Is this Free ?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'Yes, referrals.churningcanada.com is a project to support the churning community in Canada. It will always remain free.',
            },
        },
        {
            '@type': 'Question',
            name: 'Why are you asking for my Email ?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    "Reddit auth doesn't give us access to your associated email address. We need a valid email address to provide key features of the portal which is not limited to: \na) notifications when your referral links are clicked \nb) notifications on expiry of your referral links\nc) notifications when you have a message from a prospect \nd) provide you summary on other developments in churningcanada.com",
            },
        },
        {
            '@type': 'Question',
            name: 'Can I use a different email address than the one i use in Reddit?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'Yes, you can use any valid email address. It need not be the one associated with your reddit account. ',
            },
        },
    ],
};

export const FaqDataSubmission = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
        {
            '@type': 'Question',
            name: 'How long after I submit my referral link before it appears?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'The links are enabled and visible in your personal referral dashboard immediately. However, it may take upto 3 days for your link to be ranked accurately as the batch job of computing subreddit karma is performed on a rolling 3 day window.',
            },
        },
        {
            '@type': 'Question',
            name: 'Can I submit a link directly to /r/churningcanada instead of through the referral portal?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'Submissions made directly on /r/churningcanada will not be fetched by the portal. Hence we request you to kindly submit it directly from your profile page on referrals.churningcanada.com.',
            },
        },
        {
            '@type': 'Question',
            name: 'Do I need to register and sign in to submit referral links?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'Yes, as the portal is primarily conceptualized as an extension to /r/churningcanada. Only reddit users are allowed to submit the referral links. ',
            },
        },
        {
            '@type': 'Question',
            name:
                "I've read all of this FAQ and I still can't get my link to appear on the portal even after 3 days, what should i do ?",
            acceptedAnswer: {
                '@type': 'Answer',
                text: 'Please reach out to one of the folks at dev@churningcanada.com',
            },
        },
        {
            '@type': 'Question',
            name:
                'I am trying to submit a link but I am getting "Referral link format is invalid" error. Can you help?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'The links are sanitized and reviewed to ensure that no fake links are being submitted. This is done via a script. Sometimes, issuers (Amex or similar) change the link format and that may cause issues. If you face any issues please contact us by sending an email to dev@churningcanada.com. We will fix it asap :)',
            },
        },
        {
            '@type': 'Question',
            name: 'US Amex cards allow me to refer to any other cards. Can I reuse my link to multiple cards?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'Yes, you can reuse same referral link multiple times within your profile. This is enabled to accommodate the flexibility provided by American Express USA referral scheme. However, please note that you will not be able to use same referral link in another profile.',
            },
        },
        {
            '@type': 'Question',
            name: 'Can I create another profile (via multiple reddit accounts) and submit my links more than once?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'No, if the referral links are used in one profile  - system will decline the same links to be used in another profile. This is done to prevent anyone from gaming the system. Furthermore, when you create multiple profiles - you will be competing against yourself as the ranking system considers the recent 60 day activity for its ranking. TL;DR - not worth it ;)',
            },
        },
    ],
};

export const FaqDataRanking = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
        {
            '@type': 'Question',
            name: 'What is Top Referral ?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'The portal is designed to cater the interests of both referee and referrer. Top Referral is the best available referral offer that provide maximum welcome bonus for a particular credit card.',
            },
        },
        {
            '@type': 'Question',
            name: 'How is my referral ranked?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'All the referrals are ranked using fair criteria. In simple words, the ranking is based on the following weightage\n' +
                    'a) /r/churningcanada contributions - karma acquired through posts and comments in last 60 days will be normalized to  45% weightage \n' +
                    'b) To ensure a level playing field a randomization weight is calculated - on every page load, a random user from the list of users will be picked and will be assigned 45% weightage.\n' +
                    "c) To reward the efforts spent by the development team and /r/churningCanada Moderator's - 10% additional weightage is provided. However, as these weights are tied to activity of the member - only active moderator / dev team member will get a boost.",
            },
        },
        {
            '@type': 'Question',
            name: 'How often are the referral ranking updated?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'The subreddit karma weightage (45%) is computed via a batch script every 3 days. So the weights will be repopulated every 3 days.',
            },
        },
        {
            '@type': 'Question',
            name: 'I submitted my referral just now, when will my referral get ranked?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'As the script computes /r/churningCanada contribution weightage on a rolling 3 day basis - the maximum delay for your referral to be ranked is 3 calendar days.',
            },
        },
        {
            '@type': 'Question',
            name: 'I am new to /r/churningcanada - am I at disadvantage?',
            acceptedAnswer: {
                '@type': 'Answer',
                text:
                    'NO, as the portal considers contributions in the recent 60 days, there is no disadvantage to a new subreddit user. As long as you are active and contributes to the subreddit, you will be able to get to the top of the ladder.\n',
            },
        },
        {
            '@type': 'Question',
            name: '',
            acceptedAnswer: {
                '@type': 'Answer',
                text: '',
            },
        },
    ],
};
