// @flow

import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import type { ValueToDisplayNameMap } from '../../../entities/types';
import { getRandomString } from '../../utils';

type PropTypes = {
    id: string,
    displayName: string,
    data: ValueToDisplayNameMap,
    checkedValues: [string],
    onChange: (string, string) => void,
};

class CheckboxFilterGroup extends Component<PropTypes, {}> {
    onChange(value: any) {
        this.props.onChange(this.props.id, value);
    }

    render() {
        const checkboxes = [];

        Object.entries(this.props.data).forEach(([raw, display]) => {
            checkboxes.push(
                <div key={getRandomString()}>
                    <input
                        id={raw}
                        key={getRandomString()}
                        type="checkbox"
                        checked={this.props.checkedValues.includes(raw)}
                        onChange={() => this.onChange(raw)}
                    />
                    <label style={{ paddingLeft: '5px' }} htmlFor={raw}>
                        {display}
                    </label>
                </div>,
            );
        });

        return (
            <FormGroup className="mb-0">
                <Label for={this.props.id}>
                    <b>{this.props.displayName}</b>
                </Label>
                <br />
                {checkboxes}
            </FormGroup>
        );
    }
}

export default CheckboxFilterGroup;
