import React, { Component } from 'react';
import type { Suggestions } from '../../entities/types';
import CreditCard from '../../entities/models/CreditCard';
import LoadingContainer from '../basicElements/LoadingContainer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import User from '../../entities/models/User';
import { Container } from 'reactstrap';
import '../../styles/refStats.css';
import type { RefStatsData } from '../../store/refStats/types';
import { cleanUpRefStats, fetchRefStats } from '../../store/refStats/actions';

//
// PROPS
//

type PropsType = {
    // INJECTED FUNCTIONS
    fetchRefStats: () => void,
    cleanUpRefStats: () => void,
    // STATE
    data: RefStatsData,
    suggestions: Suggestions,
    // ROUTER
    history: any,
    match: any,
};

//
// STATE
//

type StateType = {};

//
// CLASS
//

class RefStatsView extends Component<PropsType, StateType> {
    //
    // FETCH
    //

    fetch() {
        this.props.fetchRefStats();
    }

    //
    // COMPONENT LIFECYCLE
    //

    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        // Clean up the state
        this.props.cleanUpRefStats();
    }

    //
    // RENDER
    //

    render() {
        let bodyComponent;

        if (this.props.data === undefined || this.props.suggestions === undefined) {
            // If any of the network resources are not here, display a loading indicator
            bodyComponent = <LoadingContainer />;
        } else {
            const cardStats = this.props.data.top_cards.map((stat) => {
                const cardSuggestion = this.props.suggestions[CreditCard.classInternalName].find(
                    (sr) => sr.id === stat.id,
                );

                return (
                    <div
                        className="card-ref-stat"
                        key={stat.id}
                        onClick={() => this.props.history.push(`/cards/${stat.id}/referrals`)}
                    >
                        <span>{cardSuggestion.display_name}</span>
                        <b>
                            {stat.click_count} {stat.click_count === 1 ? 'Click' : 'Clicks'}
                        </b>
                    </div>
                );
            });

            const userStats = this.props.data.top_users.map((stat) => {
                const userSuggestion = this.props.suggestions[User.classInternalName].find((sr) => sr.id === stat.id);

                return (
                    <div className="user-ref-stat" key={stat.id}>
                        <span>{userSuggestion.display_name}</span>
                        <b>
                            {stat.click_count} {stat.click_count === 1 ? 'Click' : 'Clicks'}
                        </b>
                    </div>
                );
            });

            bodyComponent = (
                <div>
                    <Container>
                        <h4 className="text-center separated-text mt-5 mb-4">Users with Most Referral Clicks</h4>
                        <div className="ref-stats">
                            {userStats.length > 0 ? userStats : 'Sorry, nothing to show here right now'}
                        </div>
                        <h4 className="text-center separated-text mt-3 mb-4">Cards with Most Referral Clicks</h4>
                        <div className="ref-stats">
                            {cardStats.length > 0 ? cardStats : 'Sorry, nothing to show here right now'}
                        </div>
                    </Container>
                </div>
            );
        }

        return bodyComponent;
    }
}

//
// REDUX & ROUTER
//

function mapStateToProps(state) {
    return state.refStats;
}

export default withRouter(
    connect(mapStateToProps, {
        fetchRefStats,
        cleanUpRefStats,
    })(RefStatsView),
);
