// @flow

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';

import LoadingContainer from '../basicElements/LoadingContainer';

import { RequestStatus } from '../../store/requests';

import ErrorWrapper from '../basicElements/messages/MessageWrapper';

import StyleClasses from '../../styles/StyleClasses';
import { fetchRedditUri } from '../../store/redditLogin/actions';

//
// PROPS
//

type PropsType = {
    // STATE
    status: RequestStatus,
    uri: string,
    // ROUTER
    history: any,
    // INJECTED FUNCTIONS
    fetchRedditUri: () => void,
};

//
// STATE
//

type StateType = {};

//
// CLASS
//

class RedditLoginPage extends Component<PropsType, StateType> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: PropsType) {
        // The request went thru okay
        if (prevProps.status === 'pending' && this.props.status === 'ok') {
            window.location.href = this.props.uri;
        }
    }

    submit(event) {
        this.props.fetchRedditUri();
    }

    render() {
        if (this.props.status === 'pending') {
            return <LoadingContainer />;
        }

        return (
            <div>
                <ErrorWrapper>
                    <div className={StyleClasses.pageCenter}>
                        <h2 className={StyleClasses.spacedElement}>Welcome to Churning Canada!</h2>
                        <Button className={StyleClasses.lotSpacedElement} onClick={() => this.submit()}>
                            Login via Reddit
                        </Button>
                    </div>
                </ErrorWrapper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state.redditLogin;
}

export default withRouter(connect(mapStateToProps, { fetchRedditUri })(RedditLoginPage));
